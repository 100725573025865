import React, { useCallback, useMemo } from "react"

import { useContextSelector } from "use-context-selector"

import { AlertTypeEnum } from "@unlimint/admin-ui-kit"

import { CompanyContext } from "domain/company/CompanyContext"
import { companySelectors } from "domain/company/selectors"

import { FormModeEnum } from "domain/enums/FormModeEnum"
import { FormContainer } from "domain/core/containers/FormContainer"
import { useAddNotification } from "domain/notifications/hooks/useAddNotification"

import { mapToAddCompanyReqModel } from "../../applications/mappers"
import { CompanyAddForm } from "../../../view/pages/company/CompanyPage/CompanyAddForm"

import { AddCompanyData } from "../types"
import { useHasCompany } from "../hooks/useHasCompany"
import { useFetchCompany } from "../hooks/useFetchCompany"
import { useValidateCompany } from "../hooks/useValidateCompany"

import { validate } from "./validate"

const initialState = {
  name: "",
  country: "",
  registrationNumber: "",
  registrationAddress: "",
  industry: "",
  type: "",
  description: "",
  turnover: "",

  firstName: "",
  secondName: "",
  role: "",
  email: "",
  phoneNumber: "",
  citizenship: "",
}

export const CompanyFormContainer = () => {
  const addNotification = useAddNotification()

  const companyFormData = useContextSelector(
    CompanyContext,
    companySelectors.data
  )

  const update = useContextSelector(CompanyContext, companySelectors.update)

  const { isLoading: isLoadingCompany, data, fetchCompany } = useFetchCompany()
  const { onValidate } = useValidateCompany()
  const { hasCompany, updateStatus } = useHasCompany()

  const status = useMemo(() => data?.status, [data])
  const inProcess = useMemo(() => status && status !== "newCompany", [status])

  const apiUrl = useMemo(
    () =>
      hasCompany ? "/api/v1/companies/update" : "/api/v1/companies/create",
    [hasCompany, status]
  )

  const cbSuccess = useCallback(async (currentData: AddCompanyData) => {
    update({ ...companyFormData, ...currentData })

    addNotification(
      `Success ${hasCompany ? "update" : "add"} company`,
      AlertTypeEnum.Success
    )

    await updateStatus()
    await fetchCompany()
  }, [])

  const handleValidate = useCallback(async () => {
    await onValidate()
    await fetchCompany()
  }, [onValidate])

  const cbFailure = useCallback(() => {
    addNotification(
      `Failed ${hasCompany ? "update" : "add"} company`,
      AlertTypeEnum.Error
    )
  }, [])

  return (
    <>
      <FormContainer
        mode={FormModeEnum.Add}
        validate={validate}
        initialValues={data || initialState}
        url={apiUrl}
        mapToReqModel={mapToAddCompanyReqModel}
        Form={CompanyAddForm}
        formProps={{
          inProcess,
          hasCompany,
          onValidate: handleValidate,
          currentStatus: status,
          isLoading: hasCompany ? isLoadingCompany : false,
        }}
        cbSuccess={cbSuccess}
        cbFailure={cbFailure}
      />
    </>
  )
}
