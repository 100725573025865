import { useRequest } from "domain/core/hooks/useRequest"
import { ApiMethodEnum } from "domain/core/enums"

export const useValidateCompany = () => {
  const { data, isLoading, send } = useRequest({
    url: "/api/v1/companies/send-to-aml",
    sendOnStart: false,
    method: ApiMethodEnum.Post,
  })

  return {
    onValidate: send,
    isLoading: isLoading || data === undefined,
    data: data || {},
  }
}
