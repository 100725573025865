import { apiService } from "infrastructure/services/ApiService"
import { errorService } from "infrastructure/services/ErrorService"
import { queryToString } from "domain/core/utils"
import { mapDataGridPaginationToReq } from "domain/core/mappers"

const mockRequestDelay = 1000

export const dataProvider = {
  combineUrl: (url, params = {}) => {
    const query = {
      // sort: params.sort,
    }

    if (params.pagination) {
      query.pagination = mapDataGridPaginationToReq(params.pagination)
    }

    if (params.filters) {
      query.filters = params.filters
    }

    if (params.merchantId) {
      query.merchantId = params.merchantId
    }

    if (params.methodCode) {
      query.methodCode = params.methodCode
    }

    const queryString = queryToString(query)

    return `${process.env.REACT_APP_API_URL}${url}${
      queryString ? `?${queryString}` : ""
    }`
  },

  getEntity: (url, params, options, entityMockData) => {
    const uri = dataProvider.combineUrl(url, params)
    let dataPromise

    if (entityMockData) {
      dataPromise = new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: entityMockData })
        }, mockRequestDelay)
      })
    } else {
      dataPromise = apiService.get(uri)
    }
    return dataPromise.then(({ data }) => {
      const value = options?.mapper ? options?.mapper(data) : data

      return {
        data: value || null,
      }
    })
  },

  getList: (url, params, options, gridMockData) => {
    const uri = dataProvider.combineUrl(url, params)
    let dataPromise

    if (gridMockData) {
      dataPromise = new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: gridMockData })
        }, mockRequestDelay)
      })
    } else {
      dataPromise = apiService.get(uri)
    }

    return dataPromise
      .then(({ data }) => {
        const items =
          options?.mapper && data?.list
            ? options?.mapper(data?.list)
            : data?.list

        return {
          data: items || [],
          pagination: {
            ...data.pagination,
            total: data.total,
          },
        }
      })
      .catch((err) => {
        errorService.send(err)
      })
  },

  getListWithFilter: (url, params, options, gridMockData) => {
    const uri = dataProvider.combineUrl(url)

    let dataPromise

    if (gridMockData) {
      dataPromise = new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: gridMockData })
        }, mockRequestDelay)
      })
    } else {
      dataPromise = apiService.post(uri, params)
    }

    return dataPromise
      .then(({ data }) => {
        try {
          const list = data?.list || data || []

          const items = options?.mapper && list ? options?.mapper(list) : list

          return {
            data: items || [],
            pagination: {
              ...data.pagination,
              total: data.total,
            },
          }
        } catch (e) {
          errorService.send(e)
          return null
        }
      })
      .catch((err) => {
        errorService.send(err)
        throw new Error(err)
      })
  },

  delete: (url, data) => {
    return apiService.delete(`${process.env.REACT_APP_API_URL}${url}`, {
      data,
    })
  },
}
