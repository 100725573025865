import { styled } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"

import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({}))

export const Link = styled(RouterLink)(() => ({
  color: palette.info500,
  textDecoration: "none",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}))
