import { styled } from "@mui/material"

import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({
  width: "584px",
  padding: "6px 0px",
}))

export const Items = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
  gridGap: "24px",
  gridRowGap: "50px",
}))

export const Item = styled("div")(() => ({
  width: 280,
}))
export const Label = styled("div")(() => ({
  fontSize: 13,
  lineHeight: "20px",
  fontWeight: 500,
  color: palette.black,
}))

export const Value = styled("div")(() => ({
  color: palette.black,
  fontSize: 13,
  lineHeight: "20px",
  fontWeight: 400,
  wordWrap: "break-word",
  marginTop: "6px",
}))

export const ButtonBlock = styled("div")(() => ({
  marginTop: "50px",
  "& > button:first-of-type": {
    marginRight: "12px",
  },
}))
