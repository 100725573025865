import React, { useEffect, useState, useMemo, Ref, useCallback } from "react"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"
import { useContextSelector } from "use-context-selector"
import { Header, Sidebar, IMenuElement, Button } from "@unlimint/admin-ui-kit"

import { LoadingPage } from "view/pages/common/LoadingPage"
import { menuConfig } from "view/layouts/menu.config"

import { AppContext } from "domain/common/AppContext"
import { appSelectors } from "domain/common/selectors"
import { NotificationsContainer } from "domain/notifications/NotificationsContainer"

import { authService } from "infrastructure/services/AuthService"

import { ReactComponent as Image } from "assets/images/logo.svg"
import { ReactComponent as ImageMin } from "assets/images/logo_min.svg"

import * as S from "./styled"

const LinkRef = React.forwardRef((props: IMenuElement, ref) => (
  <Link ref={ref as Ref<HTMLAnchorElement>} to={props?.to} {...props} />
))

const Logo = (
  <S.LogoIcon>
    <Image />
  </S.LogoIcon>
)
const LogoMin = (
  <S.LogoIcon>
    <ImageMin />
  </S.LogoIcon>
)

const LeftMenu = ({ email }: { email?: string }) => {
  const navigate = useNavigate()

  const onLogout = useCallback(() => {
    authService.logout().then(() => {
      navigate("/login")
    })
  }, [navigate])

  return (
    <S.MenuWrapper>
      <S.Email>{email}</S.Email>
      <Button size="small" variant="outlined" onClick={onLogout}>
        Log out
      </Button>
    </S.MenuWrapper>
  )
}

export const MainLayout = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [email, setEmail] = useState(null)
  const [open, setOpen] = useState(true)

  const navigate = useNavigate()
  const location = useLocation()
  const title = useContextSelector(AppContext, appSelectors.title)

  const menuConfigData = useMemo(() => {
    const config = menuConfig()
    const path = location?.pathname?.split("/")?.[1]

    if (path) {
      return config.map((menuItem) => {
        if (menuItem.to === `/${path}`) {
          return {
            ...menuItem,
            isSelected: true,
          }
        }

        return menuItem
      })
    }

    return config
  }, [location])

  useEffect(() => {
    authService
      .init()
      .then((data: { email?: string }) => {
        setEmail(data?.email)
        setIsLoading(false)
      })
      .catch(() => {
        authService.logout().then(() => {
          setIsLoading(false)
          navigate("/login")
        })
      })
  }, [])

  if (isLoading) {
    return (
      <S.LoadingWrapper>
        <LoadingPage />
      </S.LoadingWrapper>
    )
  }

  return (
    <S.Wrapper>
      <NotificationsContainer />
      <S.LeftBlock>
        <Sidebar
          config={menuConfigData}
          menuItemComponent={LinkRef}
          open={open}
          setOpen={setOpen}
          logo={Logo}
          logoMin={LogoMin}
        />
      </S.LeftBlock>
      <S.RightBlock>
        <Header
          title={title}
          open={open}
          userMenu={<LeftMenu email={email} />}
        />
        <Outlet />
      </S.RightBlock>
    </S.Wrapper>
  )
}
