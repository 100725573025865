import { ColorIcon, IMenuElement } from "@unlimint/admin-ui-kit"

import { useTranslate } from "infrastructure/hooks/useTranslate"

export const menuConfig = (): IMenuElement[] => {
  const config = [
    {
      label: useTranslate("sidebar.company"),
      icon: <ColorIcon name="merchants" />,
      to: "/company",
    },
    {
      label: useTranslate("sidebar.applications"),
      icon: <ColorIcon name="tokens" />,
      to: "/applications",
    },
  ]

  return config
}
