import React from "react"

import { LoginFormContainer } from "domain/login/LoginFormContainer"

import * as S from "./styled"

export const LoginPage = () => {
  return (
    <S.Wrapper>
      <LoginFormContainer />
    </S.Wrapper>
  )
}
