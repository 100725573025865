import React, { useCallback } from "react"
import { AlertTypeEnum } from "@unlimint/admin-ui-kit"

import { FormModeEnum } from "domain/enums/FormModeEnum"
import { FormContainer } from "domain/core/containers/FormContainer"
import { TUseOpenableState } from "domain/hooks/useOpenable"
import { useAddNotification } from "domain/notifications/hooks/useAddNotification"
import { useContextSelector } from "use-context-selector"

import { ConfirmBurnModal } from "view/pages/applications/ApplicationDetails/ConfirmBurnModal"

import { localizationService as ls } from "infrastructure/services/LocalizationService"
import { ApplicationsContext } from "../ApplicationsContext"

import { mapToConfirmBurnModalData } from "../mappers"
import { applicationsSelectors } from "../selectors"
import { validate } from "./validate"

type TConfirmBurnContainerProps = {
  modal: TUseOpenableState
  applicationId?: string
}

export const ConfirmBurnContainer = ({
  modal,
  applicationId,
}: TConfirmBurnContainerProps) => {
  const item = useContextSelector(
    ApplicationsContext,
    applicationsSelectors.item
  )
  const update = useContextSelector(
    ApplicationsContext,
    applicationsSelectors.update
  )

  const data = item?.data || {}

  const addNotification = useAddNotification()

  const initialState = {
    companyName: "",
    companyAddress: "",
    bankAccount: "",
    bankSwiftCode: "",
    intermediaryBank: "",
    id: applicationId,
  }

  const cbSuccess = useCallback(
    (currentData) => {
      update({
        item: {
          ...item,
          data: {
            ...data,
            status: currentData.status,
          },
        },
      })

      addNotification(
        ls.t("pages.applications.notification.confirmBurnSuccess"),
        AlertTypeEnum.Success
      )
      modal.onClose()
    },
    [update, item, data]
  )

  const cbFailure = useCallback(() => {
    addNotification(
      ls.t("pages.applications.notification.confirmBurnError"),
      AlertTypeEnum.Error
    )

    modal.onClose()
  }, [])

  return (
    <>
      <FormContainer
        mode={FormModeEnum.Add}
        validate={validate}
        initialValues={initialState}
        url="/api/v1/application/confirm"
        mapToReqModel={mapToConfirmBurnModalData}
        Form={ConfirmBurnModal}
        formProps={{
          onCancel: modal.onClose,
        }}
        cbSuccess={cbSuccess}
        cbFailure={cbFailure}
      />
    </>
  )
}
