import { ApiMethodEnum } from "domain/core/enums"
import { useRequest } from "domain/core/hooks/useRequest"

export const useVerify = (
  token: string
): {
  isLoading: boolean
  status: number
} => {
  const { status, isLoading } = useRequest({
    url: `/api/v1/users/confirm-email/${token}`,
    sendOnStart: true,
    method: ApiMethodEnum.Get,
  })

  return {
    isLoading,
    status,
  }
}
