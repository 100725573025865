import React from "react"
import { Button } from "@unlimint/admin-ui-kit"
import { Form } from "formik"

import { localizationService as ls } from "infrastructure/services/LocalizationService"

import { LoadingPage } from "view/pages/common/LoadingPage"
import { InputField } from "view/components/formik/InputField"

import * as S from "./styled"

type TConfirmBurnModalProps = {
  onCancel: () => void
  isSubmitting?: boolean
}
export const ConfirmBurnModal = ({
  onCancel,
  isSubmitting,
}: TConfirmBurnModalProps) => {
  if (isSubmitting) {
    return (
      <S.Wrapper>
        <LoadingPage />
      </S.Wrapper>
    )
  }

  return (
    <Form>
      <S.Wrapper>
        <S.Title>{ls.t("shared.confirmTx")}</S.Title>
        <S.Items>
          <S.Item>
            <S.Label>
              {ls.t("pages.applications.confirmBurn.fields.companyName")}
            </S.Label>
            <S.Value>
              <InputField name="companyName" disabled={isSubmitting} />
            </S.Value>
          </S.Item>
          <S.Item>
            <S.Label>
              {ls.t("pages.applications.confirmBurn.fields.companyAddress")}
            </S.Label>
            <S.Value>
              <InputField name="companyAddress" disabled={isSubmitting} />
            </S.Value>
          </S.Item>
          <S.Item>
            <S.Label>
              {ls.t("pages.applications.confirmBurn.fields.bankAccount")}
            </S.Label>
            <S.Value>
              <InputField name="bankAccount" disabled={isSubmitting} />
            </S.Value>
          </S.Item>
          <S.Item>
            <S.Label>
              {ls.t("pages.applications.confirmBurn.fields.bankSwiftCode")}
            </S.Label>
            <S.Value>
              <InputField name="bankSwiftCode" disabled={isSubmitting} />
            </S.Value>
          </S.Item>
          <S.Item>
            <S.Label>
              {ls.t("pages.applications.confirmBurn.fields.intermediaryBank")}
            </S.Label>
            <S.Value>
              <InputField name="intermediaryBank" disabled={isSubmitting} />
            </S.Value>
          </S.Item>
        </S.Items>

        <S.Buttons>
          <Button size="small" type="sumbit" disabled={isSubmitting}>
            {ls.t("shared.approve")}
          </Button>
          <Button
            size="small"
            color="secondary"
            onClick={onCancel}
            disabled={isSubmitting}
          >
            {ls.t("shared.close")}
          </Button>
        </S.Buttons>
      </S.Wrapper>
    </Form>
  )
}
