import { styled } from "@mui/material"

import { palette } from "view/themes/palette"

export const Error = styled("div")(() => ({
  height: 16,
  marginTop: 16,
  color: palette.error500,
  fontSize: "14px",
}))
