import axios from "axios"

import { errorService } from "./ErrorService"
import { storageService } from "./StorageService"

export const apiService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
})

apiService.getSource = () => {
  return axios.CancelToken.source()
}

apiService.interceptors.request.use(
  async (config) => {
    try {
      const token = await storageService.getItem("accessToken")

      if (token) config.headers.Authorization = `Bearer ${token}`
    } catch (e) {
      errorService.send(e)
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

apiService.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response?.status === 401 || error.response?.status === 0) {
      // eslint-disable-next-line no-undef
      window.open(`${process.env.REACT_APP_APP_URL}/login`, "_self")
    }

    return Promise.reject(error)
  }
)
