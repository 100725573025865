import { createContext } from "use-context-selector"

import { createProvider } from "domain/createProvider"

export type TAppContextState = {
  title?: string
  user?: string
  role?: string
  update?: (value: TAppContextState) => void
}

const initState: TAppContextState = {
  title: "Admin",
}

export const AppContext = createContext(initState)

export const AppProvider = createProvider(AppContext, initState)
