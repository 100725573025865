import React, { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"

import { FormModeEnum } from "domain/enums/FormModeEnum"
import { FormContainer } from "domain/core/containers/FormContainer"

import { RegisterForm } from "view/pages/register/RegisterPage/RegisterForm"

import { mapToAddReqModel } from "../mappers"
import { validate } from "./validate"
import { getError } from "./utils"

const initialState = {
  email: "",
  password: "",
  confirmPassword: "",
}
export const RegisterFormContainer = () => {
  const navigate = useNavigate()
  const [error, setError] = useState(null)

  const cbSuccess = useCallback(() => {
    navigate("/email")
  }, [])

  const cbFailure = useCallback((data) => {
    const errorCode = data.response?.data?.code
    const currentError = getError(errorCode)
    setError(currentError)
  }, [])

  const cbSubmit = useCallback(() => {
    setError(null)
  }, [])

  const onSignIn = useCallback(() => {
    navigate("/login")
  }, [navigate])

  return (
    <FormContainer
      mode={FormModeEnum.Add}
      initialValues={initialState}
      validate={validate}
      validateOnSubmit
      url="/api/v1/users/register"
      mapToReqModel={mapToAddReqModel}
      Form={RegisterForm}
      formProps={{
        error,
        onSignIn,
      }}
      cbSuccess={cbSuccess}
      cbFailure={cbFailure}
      cbSubmit={cbSubmit}
    />
  )
}
