import { styled } from "@mui/material"
import { Link } from "react-router-dom"
import { palette } from "view/themes/palette"

export const TitleWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "28px",
}))

export const Title = styled("span")(() => ({
  marginLeft: "20px",
  height: "fit-content",
  lineHeight: "28px",
  fontSize: "10px",
  fontWeight: "bold",
  color: palette.grey700,
}))

export const CustomLink = styled(Link)(() => ({
  color: palette.black,
}))
