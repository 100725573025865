import { useCallback } from "react"
import { useSafeState } from "./useSafeState"

type TAnchorEl = boolean | null | EventTarget

export type TUseOpenableState = {
  open: boolean
  anchorEl: TAnchorEl
  onOpen: (e?: Event) => void
  onClose: () => void
}

export const useOpenable = (
  defaultState: TAnchorEl = null
): TUseOpenableState => {
  const [anchorEl, setAnchorEl] = useSafeState(defaultState)

  const onOpen = useCallback((e) => setAnchorEl(e?.currentTarget || true), [])
  const onClose = useCallback(() => setAnchorEl(null), [])

  return { open: !!anchorEl, anchorEl, onOpen, onClose }
}
