import { useEffect } from "react"
import { useContextSelector } from "use-context-selector"
import { AppContext } from "domain/common/AppContext"
import { appSelectors } from "domain/common/selectors"

export const useTitle = (title) => {
  const update = useContextSelector(AppContext, appSelectors.update)

  useEffect(() => {
    update({
      title,
    })
  }, [update])

  return null
}
