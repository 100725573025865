import { useEffect } from "react"
import { useContextSelector } from "use-context-selector"

import { ApiMethodEnum } from "infrastructure/enums/ApiMethodEnum"
import { dataProvider } from "domain/core/dataProvider"
import { TUpdate } from "../../types"
import { paginationFromRes } from "../mappers"

export const FetchListContainer = ({
  url,
  method,
  context,
  selectors,
  params,
  options = {},
  gridMockData,
}) => {
  const update: TUpdate<any> = useContextSelector(context, selectors.update)

  const hashParams = JSON.stringify({
    ...params,
    pagination: {
      ...params.pagination,
      total: undefined,
    },
  })

  useEffect(() => {
    update({
      gridData: {
        isLoading: true,
      },
    })

    const dataProviderMethod =
      method === ApiMethodEnum.Post
        ? dataProvider.getListWithFilter
        : dataProvider.getList

    dataProviderMethod(url, params, options, gridMockData)
      .then((response) => {
        if (!response) return

        update({
          gridData: {
            list: response.data,
            pagination: paginationFromRes(response.pagination),
            error: null,
            isLoading: false,
          },
        })
      })
      .catch((err) => {
        update({
          gridData: {
            error: err,
            isLoading: false,
          },
        })
      })
  }, [hashParams])

  return null
}
