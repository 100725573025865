import React from "react"

import { TNotification } from "domain/notifications/types"
import { Message } from "./Message"

import * as S from "./styled"

type TNotificationProps = {
  notifications: TNotification[]
  onClose: (id: string) => void
}

export const Notifications = ({
  notifications,
  onClose,
}: TNotificationProps) => (
  <S.Wrapper>
    {notifications.map((notification) => (
      <Message
        key={notification.id}
        notification={notification}
        onClose={onClose}
      />
    ))}
  </S.Wrapper>
)
