import { localizationService as ls } from "infrastructure/services/LocalizationService"

import * as S from "./styled"

type TProps = {
  status: string
}
export const ApplicationStatusWithColor = ({ status }: TProps) => (
  <S.Wrapper>
    <S.ColorCircle color={status} />
    {ls.t(`pages.applications.status.${status}`)}
  </S.Wrapper>
)
