import { styled } from "@mui/material"
import { palette } from "view/themes/palette"
import { typography } from "@mui/system"

export const Wrapper = styled("div")(() => ({
  display: "flex",
  flexGrow: 1,
  zIndex: 1,
  minHeight: "100vh",
  backgroundColor: palette.white,
  position: "relative",
}))

export const LoadingWrapper = styled("div")(() => ({
  width: "100%",
  height: "100%",
  position: "absolute",
}))

export const MenuWrapper = styled("div")(() => ({}))

export const Email = styled("span")(() => ({
  marginRight: 16,
  color: palette.black,
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: 400,
}))
export const LeftBlock = styled("div")(() => ({
  flexShrink: 0,
}))

export const RightBlock = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 2,
}))

export const LogoIcon = styled("span")(() => ({
  ...typography,
  fontSize: "1.4rem",
  fontWeight: 500,
  color: palette.grey900,
}))
