import { ReactComponent as Image } from "assets/images/empty.svg"
import { Link } from "react-router-dom"

import * as S from "./styled"

export const CompanyNotVerifiedPage = () => (
  <S.Wrapper>
    <Image />
    <S.Text>
      You have to verify your <Link to="/company">Company</Link> before you can
      make your application.
    </S.Text>
  </S.Wrapper>
)
