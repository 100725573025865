import { styled } from "@mui/material"
import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  background: palette.grey100,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

export const FormContent = styled("div")(() => ({
  backgroundColor: palette.white,
  width: "360px",
  padding: "40px",
  boxShadow: "0 0 60px 2px rgba(0, 0, 0, 0.1)",
}))

export const Items = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
  gridGap: "24px",
  gridRowGap: "32px",
}))

export const Label = styled("div")(() => ({
  fontSize: 13,
  lineHeight: "20px",
  fontWeight: 500,
  color: palette.black,
}))

export const Value = styled("div")(() => ({
  color: palette.black,
  fontSize: 13,
  lineHeight: "20px",
  fontWeight: 400,
  wordWrap: "break-word",
  marginTop: "6px",
}))

export const ButtonBlock = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "36px",
  "& > button:first-of-type": {
    marginRight: "12px",
  },
  "& > button": {
    fontFamily: "JetBrains Mono",
  },
}))
