import { Button } from "@unlimint/admin-ui-kit"
import React from "react"
import { useNavigate } from "react-router-dom"

import * as S from "./styled"

export const NotFoundPage = () => {
  const navigation = useNavigate()
  const onClickHandler = () => {
    navigation("/")
  }

  return (
    <S.Wrapper>
      <S.Icon>404</S.Icon>
      <S.Title>This Page Does Not Exist</S.Title>
      <S.Text>
        Oops! The page you are looking for may have been removed or is
        temporarily unavailable.
      </S.Text>
      <Button size="large" color="primary" onClick={onClickHandler}>
        Go Home
      </Button>
    </S.Wrapper>
  )
}
