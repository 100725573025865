import React, { useEffect, memo } from "react"
import { Alert } from "@unlimint/admin-ui-kit"

import { useOpenable } from "domain/hooks/useOpenable"
import { TNotification } from "domain/notifications/types"

const NOTIFICATION_TIMEOUT = 5000

type TMessageProps = {
  notification: TNotification
  onClose: (id: string) => void
}
export const Message = memo(({ notification, onClose }: TMessageProps) => {
  const model = useOpenable(true)

  const handleOnClose = () => {
    onClose(notification.id)
    model.onClose()
  }

  useEffect(() => {
    if (!model.open) return

    const timerId = setTimeout(() => {
      handleOnClose()
    }, NOTIFICATION_TIMEOUT)

    return () => {
      clearTimeout(timerId)
    }
  }, [model])

  return (
    <Alert
      open={model.open}
      onClose={handleOnClose}
      type={notification.type}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      sx={{ width: "300px", pointerEvents: "auto" }}
    >
      {notification.title}
    </Alert>
  )
})
