import { TGridConfig } from "@unlimint/admin-ui-kit"

import {
  TAuditLog,
  TDataGridPagination,
  TDataGridSort,
  TFetchListParamReq,
  TGridContainerParams,
  TPagination,
  TReqPagination,
  TReqSort,
  TResPagination,
} from "./types"
import { TDataGridContext } from "../types"

export const mapDataGridPaginationToReq = (
  pagination: TDataGridPagination
): TReqPagination => {
  return {
    limit: pagination.limit,
    offset: (pagination.currentPage - 1) * pagination.limit,
  }
}

export const paginationFromRes = (
  pagination: TResPagination
): TDataGridPagination => {
  return {
    total: pagination.total,
    currentPage: pagination.offset
      ? Math.round(pagination.offset / pagination.limit) + 1
      : 1,
    limit: pagination.limit,
  }
}

export const paginationToGrid = (
  pagination: TPagination
): TDataGridPagination => {
  return {
    total: pagination?.total || pagination?.limit,
    currentPage: pagination.offset
      ? Math.round(pagination.offset / pagination.limit) + 1
      : 1,
    limit: pagination.limit,
  }
}

export const paginationToContext = (
  pagination: TDataGridPagination
): TReqPagination => {
  return {
    limit: pagination.limit,
    offset: (pagination.currentPage - 1) * pagination.limit,
  }
}

export const mapDataGridSortToReq = (sort: TDataGridSort[]): TReqSort[] => {
  return sort.map((item) => ({
    field: item.columnName,
    direction: item.direction,
  }))
}

export const mapGridContainerParamsToReq = (
  params: TGridContainerParams,
  filterToReq: any
): TFetchListParamReq => {
  const { filters, pagination, sort } = params

  return {
    filters: filterToReq ? filterToReq(filters) : undefined,
    pagination: pagination ? mapDataGridPaginationToReq(pagination) : undefined,
    sort: sort ? mapDataGridSortToReq(sort) : undefined,
  }
}

export function mapDataGridContextToGridConfig<T>(
  dataGrid: TDataGridContext<T>
): TGridConfig {
  const gridConfig: TGridConfig = {
    pagination: dataGrid.pagination,
  }

  if (dataGrid.filters) gridConfig.filter = dataGrid.filters
  if (dataGrid.sort) gridConfig.sort = dataGrid.sort

  return gridConfig
}

export function mapDtoToAuditLog(data): TAuditLog[] {
  return data.map((item) => ({
    id: item.id,
    userId: item.userId,
    entityName: item.entityName,
    entityId: item.entityId,
    operation: item.operation,
    oldValues: item.oldValues,
    newValues: item.newValues,
    createdAt: item.createdAt,
  }))
}
