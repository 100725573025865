import { ApiMethodEnum } from "domain/core/enums"
import { useRequest } from "domain/core/hooks/useRequest"

export const useFetchChainConfig = () => {
  const { data, isLoading } = useRequest({
    url: "/api/v1/refs/chain-param",
    sendOnStart: true,
    method: ApiMethodEnum.Get,
  })

  return {
    isLoading,
    data: data || [],
  }
}
