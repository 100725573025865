import { ReactComponent as Image } from "assets/images/empty.svg"

import * as S from "./styled"

type TEmptyPageProps = {
  name: string
}
export const EmptyPage = ({ name }: TEmptyPageProps) => (
  <S.Wrapper>
    <Image />
    <S.Text>{name}</S.Text>
  </S.Wrapper>
)
