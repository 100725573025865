import React, { useState, useCallback } from "react"
import { mergeWith, isArray } from "lodash"

function customizer(objValue, srcValue) {
  if (isArray(objValue)) {
    return srcValue
  }

  return undefined
}

export const createProvider = (Context, initState = {}) => {
  return ({ children }) => {
    const [state, setState] = useState(initState)

    const update = useCallback(
      (parts) => {
        setState((innerState) => {
          return mergeWith({}, innerState, parts, customizer)
        })
      },
      [setState]
    )

    const reset = useCallback(() => {
      setState(initState)
    }, [setState])

    return (
      <Context.Provider
        value={{
          ...state,
          update,
          reset,
        }}
      >
        {children}
      </Context.Provider>
    )
  }
}
