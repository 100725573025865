import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"

import { Containers } from "domain/Containers"

import { MainLayout } from "view/layouts/MainLayout"
import { AuthLayout } from "view/layouts/AuthLayout"

import { NotFoundPage } from "view/pages/common/NotFoundPage"

import { CompanyPage } from "view/pages/company/CompanyPage"

import { ApplicationsPage } from "view/pages/applications/ApplicationsPage"
import { ApplicationDetailsPage } from "view/pages/applications/ApplicationDetails"

import { LoginPage } from "view/pages/login/LoginPage"

import { RegisterPage } from "view/pages/register/RegisterPage"

import { EmailPage } from "view/pages/email/EmailPage"

import { VerifyPage } from "view/pages/verify/VerifyPage"

export const App = () => (
  <BrowserRouter>
    <Containers />
    <Routes>
      <Route path="/login" element={<AuthLayout />}>
        <Route index element={<LoginPage />} />
      </Route>
      <Route path="/register" element={<AuthLayout />}>
        <Route index element={<RegisterPage />} />
      </Route>
      <Route path="/email" element={<AuthLayout />}>
        <Route index element={<EmailPage />} />
      </Route>
      <Route path="/verify" element={<AuthLayout />}>
        <Route path=":token" element={<VerifyPage />} />
      </Route>

      <Route path="/" element={<MainLayout />}>
        <Route index element={<CompanyPage />} />

        <Route path="/company">
          <Route index element={<CompanyPage />} />
        </Route>
        <Route path="/applications">
          <Route index element={<ApplicationsPage />} />
          <Route path=":applicationId" element={<ApplicationDetailsPage />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </BrowserRouter>
)
