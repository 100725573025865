import { AppProvider } from "domain/common/AppContext"
import { NotificationsProvider } from "domain/notifications/NotificationsContext"
import { ChainConfigProvider } from "domain/chainConfig/ChainConfigContext"

const providerList = [
  [AppProvider],
  [NotificationsProvider],
  [ChainConfigProvider],
]

export const Providers = ({ children }) => {
  return providerList.reduce((acc, item) => {
    const [Provider, props] = item

    if (props) return <Provider {...props}>{acc}</Provider>

    return <Provider>{acc}</Provider>
  }, children)
}
