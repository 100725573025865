import React from "react"
import { Button } from "@unlimint/admin-ui-kit"

import {
  ApplicationStatusEnum,
  OperationEnum,
  TApplicationDetailsData,
} from "domain/applications/types"
import { TChainConfig } from "domain/chainConfig/types"
import { formatDatetime } from "domain/core/utils"
import {
  getChainConfigItemById,
  getNetworkNameById,
} from "domain/chainConfig/utils"

import { amountFormat } from "infrastructure/utils/amountFormat"

import { localizationService as ls } from "infrastructure/services/LocalizationService"

import * as S from "./styled"

type TDetailsProps = {
  data: TApplicationDetailsData
  chainConfig: TChainConfig[]
  onEdit: () => void
  onApprove: (operation: OperationEnum) => void
}

export const Details = ({
  data,
  onEdit,
  onApprove,
  chainConfig,
}: TDetailsProps) => {
  const isEditAvailable = data.status === ApplicationStatusEnum.NewApplication

  const currentChainConfigItem = getChainConfigItemById(
    data.blockchainNetwork,
    chainConfig
  )
  const currentlBockchainNetwork = getNetworkNameById(
    data.blockchainNetwork,
    chainConfig
  )

  return (
    <S.Wrapper>
      <S.Items>
        <S.Item>
          <S.Label>Amount</S.Label>
          <S.Value>{amountFormat(data.amount?.toString())}</S.Value>
        </S.Item>
        <S.Item>
          <S.Label>Collateral currency</S.Label>
          <S.Value>
            {ls.t(`pages.applications.currency.${data.collateralCurrency}`)}
          </S.Value>
        </S.Item>
        <S.Item>
          <S.Label>Status</S.Label>
          <S.Value>{ls.t(`pages.applications.status.${data.status}`)}</S.Value>
        </S.Item>
        <S.Item>
          <S.Label>Address</S.Label>
          <S.Value>{data.address}</S.Value>
        </S.Item>
        <S.Item>
          <S.Label>Blockchain Network</S.Label>
          <S.Value>{currentlBockchainNetwork}</S.Value>
        </S.Item>
        <S.Item>
          <S.Label>Created Date</S.Label>
          <S.Value>
            {formatDatetime(
              Math.floor(new Date(data.createdAt).getTime() / 1000)
            )}
          </S.Value>
        </S.Item>
      </S.Items>

      {isEditAvailable && (
        <S.ButtonBlock>
          <Button size="small" onClick={onEdit}>
            Edit application
          </Button>
          <Button size="small" onClick={() => onApprove(data.mintOrBurn)}>
            Approve
          </Button>
        </S.ButtonBlock>
      )}
    </S.Wrapper>
  )
}
