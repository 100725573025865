import { styled } from "@mui/material"
import { rotate } from "view/themes/animations"

export const Wrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  flexGrow: 1,

  svg: {
    animation: `${rotate} 1s linear infinite`,
  },
}))
