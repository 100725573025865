import { styled } from "@mui/material"
import { ApplicationStatusEnum } from "domain/applications/types"

export const ColorCirclePalette = {
  [ApplicationStatusEnum.NewApplication]: "#3AF79C",
  [ApplicationStatusEnum.InProcess]: "#ccccc0",
  [ApplicationStatusEnum.Accepted]: "#F33AF7",
  [ApplicationStatusEnum.Rejected]: "#f73a56",
}

export const ColorCircle = styled("div", {
  shouldForwardProp: (propName) => propName !== "color",
})<{ color: string }>(({ color }) => ({
  width: "8px",
  height: "8px",
  borderRadius: "50%",
  backgroundColor: ColorCirclePalette[color],
}))

export const Wrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",

  "& > div:first-of-type": {
    marginRight: "12px",
  },
}))
