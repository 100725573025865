import { styled } from "@mui/material"

import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}))

export const Icon = styled("h1")(() => ({
  fontFamily: "JetBrains Mono",
  fontSize: "83px",
  fontWeight: "bold",
  fontStyle: "normal",
  textAlign: "center",
  lineHeight: "20px",
  color: palette.primary500,
}))

export const Title = styled("h1")(() => ({
  fontFamily: "JetBrains Mono",
  fontSize: "36px",
  fontWeight: "bold",
  fontStyle: "normal",
  textAlign: "center",
  lineHeight: "20px",
  color: palette.primary500,
}))

export const Text = styled("div")(() => ({
  fontFamily: "JetBrains Mono",
  fontSize: 13,
  fontWeight: 400,
  fontStyle: "normal",
  textAlign: "center",
  lineHeight: "20px",
  color: palette.black,
  whiteSpace: "pre-wrap",
  marginTop: "12px",
  width: "350px",
  marginBottom: "32px",
}))
