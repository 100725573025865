import { styled } from "@mui/material"
import { palette } from "view/themes/palette"

export const Header = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: 22,
  marginBottom: 24,
  borderBottom: `1px solid ${palette.grey200}`,
}))

export const Title = styled("div")(() => ({
  fontSize: 16,
  lineHeight: "20px",
  fontWeight: 500,
}))

export const ErrorPageWrapper = styled("div")(() => ({
  height: "calc(100% - 55px)",
}))

export const EmptyListWrapper = styled("div")(() => ({
  width: "100%",
  height: "463px",
}))
