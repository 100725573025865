export type TApplicationData = {
  id: string
  mintOrBurn: string
  amount: string
  status: string
  collateralCurrency: string
  blockchainNetwork: string

  companyID?: string
  externalID?: string
  data?: {
    actualAmount?: number
    actualTargetNetwork?: string
    notes?: any[]
    txHash?: string
  }
  createdAt?: string
}

export enum ApplicationStatusEnum {
  NewApplication = "newApplication",
  InProcess = "inProcess",
  Accepted = "accepted",
  Rejected = "rejected",
}

export enum OperationEnum {
  Mint = "mint",
  Burn = "burn",
}

export type TApplicationNote = {
  id: string | number
  description: string
  createdAt: number
}

export type TApplicationDetailsData = {
  id: string | number
  amount: number
  status: string
  collateralCurrency: string
  mintOrBurn: OperationEnum
  blockchainNetwork: string
  address: string

  companyID: string
  externalID: string
  data: {
    actualAmount: number
    actualTargetNetwork: string
    notes: any[]
    txHash: string
  }

  createdAt?: string
}

export type TApplicationDetailsErrors = {
  amount?: string
  status?: string
  collateralCurrency?: string
  address?: string
}

export type TApplicationAddDataErrors = {
  companyId?: string
  operation?: string
  amount?: string
  collateralCurrency?: string
  blockchainNetwork?: string
  address?: string
}

export type TApplicationDetailsEdit = {
  id: string
  amount: number
  status: string
  currency: string
  address: string
  operation: string
  targetNetwork: string
  companyID?: string
  externalID?: string
  data: {
    actualAmount: number
    actualTargetNetwork: string
    notes: any[]
    txHash: string
  }
}

export type TConfirmMintData = {
  id: string
  amount?: number
  currency?: string
}

export type TConfirmBurnData = {
  id: string
  companyName: string
  companyAddress: string
  bankAccount: string
  bankSwiftCode: string

  intermediaryBank?: string
}

export type TConfirmMintDataRequest = {
  id: string
}

export type TConfirmBurnDataError = {
  companyName?: string
  companyAddress?: string
  bankAccount?: string
  bankSwiftCode?: string
}
