import React, { useCallback, useMemo, useState, useEffect } from "react"
import { useContextSelector } from "use-context-selector"
import { useNavigate } from "react-router-dom"

import {
  Button,
  DataGrid,
  Menu,
  Dialog,
  GridActionsCellItem,
  TGridConfig,
  IFilterConfig,
  SelectTypeEnum,
} from "@unlimint/admin-ui-kit"

import { useOpenable } from "domain/hooks/useOpenable"
import { FetchListContainer } from "domain/core/containers/FetchListContainer"
import {
  mapDataGridContextToGridConfig,
  mapGridContainerParamsToReq,
} from "domain/core/mappers"
import { TDataGridContext, TUpdate } from "domain/types"

import { ApiMethodEnum } from "infrastructure/enums/ApiMethodEnum"

import { ButtonBlock } from "view/components/ButtonBlock"
import { SearchBlock } from "view/components/SearchBlock"
import { ErrorPage } from "view/pages/common/ErrorPage"
import { EmptyPage } from "view/pages/common/EmptyPage"
import { SelectBlock } from "view/components/SelectBlock"

import { TFetchListParamReq, TGridContainerParams } from "../types"

export type TGridContainerProps = {
  url: string
  emptyText: string
  method?: ApiMethodEnum
  columns: any[]
  context: any
  selectors: any
  dataToRow: (item: any) => void
  filterToReq?: (filters: IFilterConfig) => void
  initParams?: TGridContainerParams
  searchBlockProps?: any
  selectBlockProps?: any
  buttonProps?: any
  actionMenu?: any
  isDetails?: boolean
  isEditable?: boolean
  isDeletable?: boolean
  submenu?: any
  DetailsModal?: any
  AddForm?: any
  EditForm?: any
  DeleteModal?: any
  onRowClick?: any
  onRowSelect?: (value: Array<string | number>) => void
  gridMockData?: any
  selectType?: SelectTypeEnum
}

export function GridContainer({
  url,
  emptyText,
  method = ApiMethodEnum.Post,
  columns,
  initParams,
  context,
  selectors,
  dataToRow,
  filterToReq,
  searchBlockProps,
  buttonProps,
  actionMenu,
  isDetails,
  isEditable,
  isDeletable,
  submenu,
  DetailsModal,
  AddForm,
  EditForm,
  DeleteModal,
  onRowClick,
  onRowSelect,
  gridMockData,
  selectType,
  selectBlockProps,
}: TGridContainerProps) {
  const navigate = useNavigate()

  const gridData: TDataGridContext<any> = useContextSelector(
    context,
    selectors.gridData
  )
  const update: TUpdate<any> = useContextSelector(context, selectors.update)

  const detailsModal = useOpenable()
  const addModal = useOpenable()
  const editModal = useOpenable()
  const deleteModal = useOpenable()

  useEffect(() => {
    const { sort, filters, pagination } = initParams

    update({
      gridData: {
        isInited: true,
        pagination,
        sort,
        filters,
      },
    })
  }, [])

  const [editItem, setEditItem] = useState(null)
  const [filteredData, setFilteredData] = useState(
    gridData?.list?.length ? gridData?.list : []
  )

  const params = useMemo<TFetchListParamReq>(() => {
    return mapGridContainerParamsToReq(gridData, filterToReq)
  }, [gridData])

  const config = useMemo<TGridConfig>(
    () => mapDataGridContextToGridConfig(gridData),
    [gridData]
  )

  useEffect(() => {
    setFilteredData(gridData?.list?.length ? gridData?.list : [])
  }, [gridData])

  const options = useMemo(
    () => ({
      mapper: (data) => data.map(dataToRow),
    }),
    [dataToRow]
  )

  const actionColumn = useMemo(
    () => ({
      field: "actions",
      type: "actions",
      align: "right",
      flex: 1,
      width: 28,
      getActions: ({ row }) => {
        const menuItems = []

        if (isDetails)
          menuItems.push(
            <GridActionsCellItem
              label="Show details"
              showInMenu
              onClick={(e) => {
                setEditItem(row)
                detailsModal.onOpen(e)
              }}
            />
          )

        if (isEditable)
          menuItems.push(
            <GridActionsCellItem
              label="Edit"
              showInMenu
              onClick={(e) => {
                setEditItem(row)
                editModal.onOpen(e)
              }}
            />
          )

        if (isDeletable)
          menuItems.push(
            <GridActionsCellItem
              label="Delete"
              showInMenu
              onClick={(e) => {
                setEditItem(row)
                deleteModal.onOpen(e)
              }}
            />
          )

        if (submenu && submenu.length) {
          submenu.forEach((item) => {
            menuItems.push(
              <GridActionsCellItem
                label={item.isSwitchTitle ? item.title(row) : item.title}
                showInMenu
                onClick={() => item.onClick(row)}
              />
            )
          })
        }

        return menuItems
      },
    }),
    [
      isDetails,
      isEditable,
      isDeletable,
      submenu,
      detailsModal,
      setEditItem,
      editModal,
      deleteModal,
    ]
  )

  const handlerChange = useCallback(
    (newParams) => {
      const { sort, filter, pagination } = newParams
      update({
        gridData: {
          pagination,
          sort,
          filters: filter,
        },
      })
    },
    [update]
  )

  const handlerSearch = useCallback(
    (data) => {
      return setFilteredData(data)
    },
    [setFilteredData]
  )

  const mergedColumns = useMemo(
    () => [...columns, actionColumn],
    [columns, actionColumn]
  )

  const gridLoading = gridData.isLoading

  return (
    <>
      {gridData.isInited && (
        <FetchListContainer
          url={url}
          method={method}
          context={context}
          selectors={selectors}
          params={params}
          options={options}
          gridMockData={gridMockData}
        />
      )}

      <DataGrid
        columns={mergedColumns}
        rows={filteredData}
        loading={gridLoading}
        error={gridData.error}
        config={config}
        onChange={handlerChange}
        onRowClick={onRowClick}
        onRowSelect={onRowSelect}
        selectType={selectType}
        ErrorScreen={<ErrorPage />}
        EmptyScreen={<EmptyPage name={emptyText} />}
        SearchBlock={
          searchBlockProps && (
            <SearchBlock
              list={gridData.list}
              columns={searchBlockProps.columns}
              placeholder={searchBlockProps.placeholder}
              onChange={handlerSearch}
            />
          )
        }
        SelectBlock={
          selectBlockProps && <SelectBlock text={selectBlockProps.text} />
        }
        ButtonsBlock={
          (buttonProps || actionMenu) && (
            <ButtonBlock>
              {buttonProps && (
                <>
                  <Button
                    size="small"
                    onClick={
                      buttonProps.link
                        ? () => navigate(buttonProps.link)
                        : buttonProps.onClick || addModal.onOpen
                    }
                    disabled={buttonProps.disabled || gridLoading}
                  >
                    {buttonProps.title}
                  </Button>
                </>
              )}
              {actionMenu && <Menu items={actionMenu} />}
            </ButtonBlock>
          )
        }
      />

      {DetailsModal && (
        <Dialog open={detailsModal.open} onClose={detailsModal.onClose}>
          <DetailsModal modal={detailsModal} data={editItem} />
        </Dialog>
      )}

      {AddForm && (
        <Dialog open={addModal.open} onClose={addModal.onClose}>
          <AddForm modal={addModal} />
        </Dialog>
      )}

      {EditForm && (
        <Dialog open={editModal.open} onClose={editModal.onClose}>
          <EditForm modal={editModal} formData={editItem} />
        </Dialog>
      )}

      {DeleteModal && (
        <Dialog open={deleteModal.open} onClose={deleteModal.onClose}>
          <DeleteModal modal={deleteModal} formData={editItem} />
        </Dialog>
      )}
    </>
  )
}
