import { useMemo } from "react"
import { useContextSelector } from "use-context-selector"

import { ApiMethodEnum } from "infrastructure/enums/ApiMethodEnum"
import { localizationService as ls } from "infrastructure/services/LocalizationService"
import { amountFormat } from "infrastructure/utils/amountFormat"

import {
  ApplicationsContext,
  ApplicationsProvider,
} from "domain/applications/ApplicationsContext"
import { applicationsSelectors } from "domain/applications/selectors"
import { dataToRow } from "domain/applications/mappers"
import { GridContainer } from "domain/core/containers/GridContainer"
import { formatDatetime } from "domain/core/utils"
import { useTitle } from "domain/common/hooks/useTitle"
import { AddApplicationContainer } from "domain/applications/AddApplicationContainer"
import { ChainConfigContext } from "domain/chainConfig/ChainConfigContext"
import { chainConfigSelectors } from "domain/chainConfig/selectors"
import { getNetworkNameById } from "domain/chainConfig/utils"
import { useHasCompany } from "domain/applications/hooks/useHasCompany"

import { PageContent } from "view/components/PageContent"
import { ApplicationStatusWithColor } from "view/pages/applications/ApplicationStatusWithColor"

import * as S from "./styled"
import { MintOrBurnValue } from "./constants"
import { CompanyNotVerifiedPage } from "./CompanyNotVerifiedPage"

type PageProps = {
  title: string
}
export const Page = ({ title }: PageProps) => {
  const { isActive } = useHasCompany()

  const chainConfig = useContextSelector(
    ChainConfigContext,
    chainConfigSelectors.data
  )
  const chainConfigData = chainConfig || []

  useTitle(title)

  const initParams = {}

  const columns = useMemo(() => {
    return [
      {
        field: "id",
        headerName: "Application Id",
        width: 200,
        renderCell: ({ value }) => (
          <S.Link to={`/applications/${value}`}>{value}</S.Link>
        ),
      },
      {
        field: "mintOrBurn",
        headerName: "Operation",
        width: 120,
        renderCell: ({ value }) => MintOrBurnValue[value],
      },
      {
        field: "amount",
        headerName: "Amount",
        width: 120,
        renderCell: ({ value }) => amountFormat(value),
      },
      {
        field: "blockchainNetwork",
        headerName: "Network",
        width: 120,
        renderCell: ({ value }) => getNetworkNameById(value, chainConfigData),
      },
      {
        field: "collateralCurrency",
        headerName: "Currency",
        width: 150,
        renderCell: ({ value }) => ls.t(`shared.tokenCurrency.${value}`),
      },
      {
        field: "createdAt",
        headerName: "Created Date",
        width: 240,
        renderCell: ({ value }) =>
          formatDatetime(Math.floor(new Date(value).getTime() / 1000)),
      },
      {
        field: "status",
        headerName: "Status",
        width: 200,
        renderCell: ({ value }) => (
          <ApplicationStatusWithColor status={value} />
        ),
      },
    ]
  }, [chainConfigData])

  if (!isActive) {
    return <CompanyNotVerifiedPage />
  }

  return (
    <S.Wrapper>
      <GridContainer
        url="/api/v1/application/list"
        emptyText={`No ${title} found`}
        method={ApiMethodEnum.Post}
        columns={columns}
        initParams={initParams}
        context={ApplicationsContext}
        selectors={applicationsSelectors}
        dataToRow={dataToRow}
        AddForm={AddApplicationContainer}
        buttonProps={{ title: "Add new" }}
      />
    </S.Wrapper>
  )
}

export const ApplicationsPage = () => (
  <ApplicationsProvider>
    <PageContent>
      <Page title={ls.t("pages.applications.title")} />
    </PageContent>
  </ApplicationsProvider>
)
