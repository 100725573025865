import { styled } from "@mui/material"
import { palette } from "view/themes/palette"

export const Content = styled("div")(() => ({
  marginBottom: 40,

  "& > div": {
    display: "flex",
    alignItems: "center",
  },
}))

export const Item = styled("div")(() => ({
  justifyContent: "space-between",
  "& + &": {
    marginTop: "8px",
  },
}))

export const Label = styled("span")(() => ({
  fontFamily: "JetBrains Mono",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "20px",
}))

export const Value = styled("span")(() => ({
  fontFamily: "JetBrains Mono",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "13px",
  lineHeight: "16px",
  marginLeft: "4px",
}))

export const Wrapper = styled("div")(() => ({
  width: 500,
  height: 348,
}))

export const Title = styled("div")(() => ({
  fontFamily: "JetBrains Mono",
  fontWeight: 600,
  fontSize: 16,
  lineHeight: "20px",
  marginBottom: 32,
}))

export const Text = styled("div")(() => ({
  fontFamily: "JetBrains Mono",
  fontSize: 14,
  fontWeight: 400,
  fontStyle: "normal",
  textAlign: "left",
  lineHeight: "20px",
  color: palette.grey500,
  whiteSpace: "pre-wrap",
  marginBottom: 50,
}))

export const Buttons = styled("div")(() => ({
  display: "flex",
  gap: 12,
}))
