import {
  ApplicationStatusEnum,
  TApplicationData,
  TApplicationDetailsData,
  TApplicationDetailsEdit,
  TConfirmBurnData,
  TConfirmMintData,
  TConfirmMintDataRequest,
} from "./types"
import { AddCompanyData } from "../company/types"

export const dataToRow = (item): TApplicationData => ({
  id: item.id,
  mintOrBurn: item.operation,
  amount: item.amount,
  status: item.status,
  collateralCurrency: item.currency,
  blockchainNetwork: item.targetNetwork,
  createdAt: item.createdAt,
})

export const dataAddToRow = (item): TApplicationData => ({
  ...dataToRow(item),
})

export const dataToEntity = (item) => ({
  ...dataToRow(item),
  collateralCurrency: item.currency,
  blockchainNetwork: item.targetNetwork,
  address: item.address,
  txDate: item.tx_date,
  companyID: item.companyID,
  externalID: item.externalID,
  data: {
    actualAmount: 0,
    actualTargetNetwork: "",
    notes: [],
    txHash: [],
  },
})

export const mapToEditFormData = (value: TApplicationDetailsData) => ({
  id: value.id,
  amount: value.amount,
  blockchainNetwork: value.blockchainNetwork,
  collateralCurrency: value.collateralCurrency,
  mintOrBurn: value.mintOrBurn,
  status: value.status,
  address: value.address,
  companyID: value.companyID,
  externalID: value.externalID,
  data: {
    actualAmount: 0,
    actualTargetNetwork: "",
    notes: [],
    txHash: [],
  },
})

export const mapToAddReqModel = (value) => ({
  operation: value.operation,
  amount: value.amount,
  targetNetwork: value.blockchainNetwork,
  currency: value.collateralCurrency,
  status: ApplicationStatusEnum.NewApplication,
  address: value.address,
  data: {
    actualAmount: 0,
    actualTargetNetwork: "",
    notes: [],
    txHash: "",
  },
})

export const mapToEditReqModel = (value): TApplicationDetailsEdit => ({
  id: value.id,
  amount: value.amount,
  status: value.status,
  currency: value.collateralCurrency,
  address: value.address,
  companyID: value.companyID,
  externalID: value.externalID,
  targetNetwork: value.blockchainNetwork,
  operation: value.mintOrBurn,
  data: {
    actualAmount: 0,
    actualTargetNetwork: "",
    notes: [],
    txHash: "",
  },
})

export const mapToConfirmModalData = (value): TConfirmMintData => ({
  id: value.id,
  amount: value.amount,
  currency: value.collateralCurrency,
})

export const mapToConfirmBurnModalData = (value): TConfirmBurnData => ({
  id: value.id,
  companyName: value.companyName,
  companyAddress: value.companyAddress,
  bankAccount: value.bankAccount,
  bankSwiftCode: value.bankSwiftCode,
  intermediaryBank: value?.intermediaryBank,
})

export const mapToConfirmMintModalData = (value): TConfirmMintDataRequest => ({
  id: value.id,
})

export const mapToAddCompanyReqModel = (value) => ({
  status: "",
  businessInfo: {
    name: value.name,
    country: value.country,
    registrationNumber: value.registrationNumber,
    registrationAddress: value.registrationAddress,
    industry: value.industry,
    type: value.type,
    description: value.description,
    turnover: Number(value.turnover),
  },
  personInfo: {
    firstName: value.firstName,
    lastName: value.secondName,
    role: value.role,
    email: value.email,
    phone: value.phoneNumber,
    citizenship: value.citizenship,
  },
})

export const dataToEntityCompany = (data: AddCompanyData) => ({
  id: data.id,
  status: data.status,

  name: data.businessInfo.name,
  country: data.businessInfo.country,
  registrationNumber: data.businessInfo.registrationNumber,
  registrationAddress: data.businessInfo.registrationAddress,
  industry: data.businessInfo.industry,
  type: data.businessInfo.type,
  description: data.businessInfo.description,
  turnover: Number(data.businessInfo.turnover),

  firstName: data.personInfo.firstName,
  secondName: data.personInfo.lastName,
  role: data.personInfo.role,
  email: data.personInfo.email,
  phoneNumber: data.personInfo.phone,
  citizenship: data.personInfo.citizenship,
})
