import React from "react"
import { Formik } from "formik"

import { apiService } from "infrastructure/services/ApiService"
import { errorService } from "infrastructure/services/ErrorService"

import { FormModeEnum } from "domain/enums/FormModeEnum"

export const FormContainer = ({
  mode,
  formProps,
  initialValues,
  validate,
  validateOnSubmit = false,
  url,
  mapToReqModel,
  Form,
  cbSuccess,
  cbFailure,
  cbSubmit,
  mockData,
}) => {
  const handlerSubmit = (values, { setSubmitting }) => {
    cbSubmit?.()

    let method

    if (mode === FormModeEnum.Mock && mockData !== null) {
      method = () => {
        return new Promise((resolve) => {
          setTimeout(() => {
            const result = {
              ...mockData,
              ...mapToReqModel(values),
            }
            resolve({ data: result })
          }, 1000)
        })
      }
    } else if (mode === FormModeEnum.Add) {
      method = apiService.post
    } else {
      method = apiService.put
    }

    method(url, mapToReqModel ? mapToReqModel(values) : values)
      .then((response) => {
        formProps.onClose?.()

        cbSuccess(response?.data, values)
        setSubmitting(false)
      })
      .catch((err) => {
        errorService.send(err)
        setSubmitting(false)
        cbFailure?.(err)
      })
  }

  return (
    <>
      <Formik
        enableReinitialize
        validateOnBlur={!validateOnSubmit}
        initialValues={initialValues}
        validate={validate}
        onSubmit={handlerSubmit}
      >
        {(formikProps) => (
          <Form
            mode={mode}
            title={formProps?.title}
            actionButtonTitle={formProps?.actionButtonTitle}
            {...formProps}
            {...formikProps}
          />
        )}
      </Formik>
    </>
  )
}
