import React, { useMemo } from "react"
import { isEmpty } from "lodash"
import { Button } from "@unlimint/admin-ui-kit"
import { Form } from "formik"

import { TApplicationDetailsErrors } from "domain/applications/types"
import { currencyValues } from "domain/applications/constants"
import { TChainConfig } from "domain/chainConfig/types"

import { InputField } from "view/components/formik/InputField"
import { SelectField } from "view/components/formik/SelectField"

import { localizationService } from "infrastructure/services/LocalizationService"

import * as S from "./styled"

type TFormProps = {
  onCancel: () => void
  isSubmitting: boolean
  isLoading: boolean
  chainConfig: TChainConfig[]
  errors?: TApplicationDetailsErrors
}

export const ApplicationEditForm = ({
  onCancel,
  isSubmitting,
  isLoading,
  errors,
  chainConfig,
}: TFormProps) => {
  const collateralCurrencyOptions = useMemo(
    () =>
      currencyValues.map((value) => ({
        label: localizationService.t(`pages.applications.currency.${value}`),
        value,
      })),
    []
  )

  const blockchainNetworkOptions = useMemo(
    () =>
      chainConfig.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    [chainConfig]
  )

  return (
    <Form>
      <S.Wrapper>
        <S.Items>
          <div>
            <S.Label>Amount</S.Label>
            <S.Value>
              <InputField type="number" name="amount" disabled={isSubmitting} />
            </S.Value>
          </div>
          <div>
            <S.Label>Collateral currency</S.Label>
            <S.Value>
              <SelectField
                name="collateralCurrency"
                disabled={isSubmitting}
                options={collateralCurrencyOptions}
              />
            </S.Value>
          </div>
          <div>
            <S.Label>Blockchain network</S.Label>
            <S.Value>
              <SelectField
                name="blockchainNetwork"
                disabled={isSubmitting}
                options={blockchainNetworkOptions}
              />
            </S.Value>
          </div>
          <div>
            <S.Label>Address</S.Label>
            <S.Value>
              <InputField name="address" disabled={isSubmitting} />
            </S.Value>
          </div>
        </S.Items>

        <S.ButtonBlock>
          <Button
            size="small"
            type="submit"
            disabled={isSubmitting || isLoading || !isEmpty(errors)}
          >
            Save
          </Button>
          <Button size="small" onClick={onCancel} color="secondary">
            Cancel
          </Button>
        </S.ButtonBlock>
      </S.Wrapper>
    </Form>
  )
}
