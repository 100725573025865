import { useRequest } from "domain/core/hooks/useRequest"
import { ApiMethodEnum } from "domain/core/enums"

import { dataToEntityCompany } from "../../applications/mappers"

export const useFetchCompany = () => {
  const { data, isLoading, send } = useRequest({
    url: "/api/v1/companies/info",
    sendOnStart: true,
    method: ApiMethodEnum.Get,
  })

  return {
    isLoading,
    fetchCompany: send,
    data: data ? dataToEntityCompany(data) : (data as undefined),
  }
}
