import { useRequest } from "domain/core/hooks/useRequest"
import { ApiMethodEnum } from "domain/core/enums"

export const useHasCompany = (): {
  isLoading: boolean
  hasCompany?: boolean
  isActive?: boolean
} => {
  const { data, isLoading } = useRequest({
    url: "/api/v1/users/has-company",
    sendOnStart: true,
    method: ApiMethodEnum.Get,
  })

  return {
    isLoading,
    hasCompany: data?.hasCompany || null,
    isActive: data?.isActive || null,
  }
}
