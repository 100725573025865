import { CompanyProvider } from "domain/company/CompanyContext"
import { useTitle } from "domain/common/hooks/useTitle"

import { localizationService as ls } from "infrastructure/services/LocalizationService"

import { PageContent } from "view/components/PageContent"

import { CompanyFormContainer } from "domain/company/CompanyFormContainer"

import * as S from "./styled"

const Page = () => {
  useTitle(ls.t("pages.company.title"))
  return (
    <S.Wrapper>
      <CompanyFormContainer />
    </S.Wrapper>
  )
}

export const CompanyPage = () => (
  <CompanyProvider>
    <PageContent>
      <Page />
    </PageContent>
  </CompanyProvider>
)
