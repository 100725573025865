import { styled } from "@mui/material"
import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({
  width: "584px",
}))

export const Items = styled("div")(() => ({
  padding: "24px 0",
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
  gridGap: "24px",
  width: "888px",
}))

export const SvgWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",

  "& > svg": {
    width: "16px",
    height: "16px",
  },
}))

export const Item = styled("div")(() => ({
  width: "280px",
}))

export const Label = styled("div")(() => ({
  color: palette.grey500,
  fontSize: 13,
  lineHeight: "20px",
  fontWeight: 400,
}))

export const Value = styled("div")(() => ({
  color: palette.black,
  fontSize: 13,
  lineHeight: "20px",
  fontWeight: 400,
  wordWrap: "break-word",
}))

export const ButtonBlock = styled("div")(() => ({
  marginTop: "8px",
  "& > button:first-of-type": {
    marginRight: "12px",
  },
}))

export const Link = styled("a")(() => ({
  display: "block",
  width: "280px",
  fontFamily: "JetBrains Mono",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "13px",
  color: palette.info500,
  textDecoration: "none",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}))
