import { Form } from "formik"
import { isEmpty } from "lodash"

import { Button } from "@unlimint/admin-ui-kit"

import { InputField } from "view/components/formik/InputField"
import { localizationService } from "infrastructure/services/LocalizationService"

import { LoadingPage } from "../../../common/LoadingPage"

import * as S from "./styled"

const StringInput = ({ isDisabled, title, name }) => {
  return (
    <S.Item>
      <S.Label>{title}</S.Label>
      <S.Value>
        <InputField type="string" name={name} disabled={isDisabled} />
      </S.Value>
    </S.Item>
  )
}

export const CompanyAddForm = ({
  isSubmitting,
  currentStatus,
  hasCompany,
  inProcess,
  onValidate,
  isLoading,
  errors,
}) => {
  const isSubmitOrProcess = isSubmitting || inProcess

  if (isLoading) {
    return (
      <S.Wrapper>
        <LoadingPage />
      </S.Wrapper>
    )
  }

  return (
    <Form>
      <S.Wrapper>
        <S.Title>Business Information</S.Title>
        <S.Items>
          <StringInput
            title="Name"
            name="name"
            isDisabled={isSubmitOrProcess}
          />
          <StringInput
            title="Country"
            name="country"
            isDisabled={isSubmitOrProcess}
          />
          <StringInput
            title="Registration number"
            name="registrationNumber"
            isDisabled={isSubmitOrProcess}
          />
          <StringInput
            title="Registration address"
            name="registrationAddress"
            isDisabled={isSubmitOrProcess}
          />
          <StringInput
            title="Industry"
            name="industry"
            isDisabled={isSubmitOrProcess}
          />
          <StringInput
            title="Type"
            name="type"
            isDisabled={isSubmitOrProcess}
          />
          <StringInput
            title="Description"
            name="description"
            isDisabled={isSubmitOrProcess}
          />
          <S.Item>
            <S.Label>Turnover</S.Label>
            <S.Value>
              <InputField
                type="number"
                name="turnover"
                disabled={isSubmitOrProcess}
              />
            </S.Value>
          </S.Item>
        </S.Items>
        <br />
        <S.Title>Personal Information</S.Title>
        <S.Items>
          <StringInput
            title="First Name"
            name="firstName"
            isDisabled={isSubmitOrProcess}
          />
          <StringInput
            title="Second Name"
            name="secondName"
            isDisabled={isSubmitOrProcess}
          />
          <StringInput
            title="Role"
            name="role"
            isDisabled={isSubmitOrProcess}
          />
          <StringInput
            title="Email"
            name="email"
            isDisabled={isSubmitOrProcess}
          />
          <StringInput
            title="Phone number"
            name="phoneNumber"
            isDisabled={isSubmitOrProcess}
          />
          <StringInput
            title="Citizenship"
            name="citizenship"
            isDisabled={isSubmitOrProcess}
          />
        </S.Items>
        {currentStatus && (
          <S.Title>
            Current Status:{" "}
            {localizationService.t(`pages.company.status.${currentStatus}`)}
          </S.Title>
        )}
        <S.ButtonBlock>
          <Button
            size="small"
            type="submit"
            disabled={isSubmitOrProcess || !isEmpty(errors)}
          >
            {hasCompany ? "Update company" : "Create company"}
          </Button>
          <Button
            size="small"
            onClick={onValidate}
            disabled={isSubmitOrProcess || !isEmpty(errors)}
          >
            Send to AML
          </Button>
        </S.ButtonBlock>
      </S.Wrapper>
    </Form>
  )
}
