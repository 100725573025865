import React from "react"
import { errorService } from "infrastructure/services/ErrorService"

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    errorService.send(error)
    return { hasError: true }
  }

  componentDidCatch(error) {
    errorService.send(error)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }

    return this.props.children
  }
}
