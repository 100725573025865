import React from "react"

import { Field } from "formik"

import { StyledInputBase } from "./styled"

const replaceNumber = (value) => {
  return String(value)
    .replace(/[^.\d]/g, "")
    .replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2")
}

export const InputField = ({
  name,
  label,
  disabled,
  cleanable = true,
  type = "text",
  size = "small",
  rows,
  isExponencial,
  placeholder,
}) => {
  return (
    <Field name={name}>
      {({ field, form: { setFieldValue, errors, touched } }) => (
        <StyledInputBase
          type={isExponencial ? "text" : type}
          label={label}
          size={size}
          fullWidth
          name={name}
          error={!!errors[name] && !!touched[name]}
          helperText={!!touched[name] && errors[name]}
          onChange={field.onChange}
          onBlur={field.onBlur}
          value={isExponencial ? replaceNumber(field.value) : field.value}
          disabled={disabled}
          cleanable={cleanable}
          onClickEndIcon={() => setFieldValue(name, "")}
          multiline={!!rows}
          rows={rows}
          placeholder={placeholder}
        />
      )}
    </Field>
  )
}
