import React, { useCallback } from "react"
import { useContextSelector } from "use-context-selector"

import { NotificationsContext } from "domain/notifications/NotificationsContext"
import { notificationsSelectors } from "domain/notifications/selectors"

import { Notifications } from "view/components/Notifications"
import { removeNotification } from "./utils"

export const NotificationsContainer = () => {
  const notifications = useContextSelector(
    NotificationsContext,
    notificationsSelectors.notifications
  )
  const update = useContextSelector(
    NotificationsContext,
    notificationsSelectors.update
  )

  const handleOnClose = useCallback(
    (id: string) => {
      update({
        notifications: removeNotification(notifications, id),
      })
    },
    [update, notifications]
  )

  return <Notifications notifications={notifications} onClose={handleOnClose} />
}
