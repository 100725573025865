import React from "react"
import { Outlet } from "react-router-dom"

import * as S from "./styled"

export const AuthLayout = () => (
  <S.Wrapper>
    <S.FormContent>
      <Outlet />
    </S.FormContent>
  </S.Wrapper>
)
