import { AlertTypeEnum } from "@unlimint/admin-ui-kit"
import { uniqueId } from "lodash"

import { TNotification } from "./types"

export const appendNotification = (
  notifications: TNotification[],
  notification: TNotification
): TNotification[] => [...notifications, notification]

export const createNotification = (
  title: string,
  type: AlertTypeEnum
): TNotification => ({
  id: uniqueId(),
  title,
  type,
})

export const removeNotification = (
  notifications: TNotification[],
  id: string
): TNotification[] =>
  notifications.filter((notification) => notification.id === id)
