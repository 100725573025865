import { AlertTypeEnum } from "@unlimint/admin-ui-kit"
import { useContextSelector } from "use-context-selector"

import { NotificationsContext } from "domain/notifications/NotificationsContext"
import { notificationsSelectors } from "domain/notifications//selectors"
import { appendNotification, createNotification } from "../utils"

type TAddNotification = (title: string, type: AlertTypeEnum) => void
export const useAddNotification = (): TAddNotification => {
  const notifications = useContextSelector(
    NotificationsContext,
    notificationsSelectors.notifications
  )
  const update = useContextSelector(
    NotificationsContext,
    notificationsSelectors.update
  )

  return (title, type) => {
    update({
      notifications: appendNotification(
        notifications,
        createNotification(title, type)
      ),
    })
  }
}
