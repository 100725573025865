import React, { useCallback } from "react"
import { Button } from "@unlimint/admin-ui-kit"
import { useNavigate } from "react-router-dom"

import { localizationService as ls } from "infrastructure/services/LocalizationService"

import { AuthFormHeader } from "view/components/AuthFormHeader"

import * as S from "./styled"

export const EmailPage = () => {
  const navigate = useNavigate()

  const onClickHandler = useCallback(() => {
    navigate("/login")
  }, [])

  return (
    <S.Wrapper>
      <AuthFormHeader title={ls.t("pages.email.title")} />

      <S.Text>{ls.t("pages.email.text")}</S.Text>

      <S.ButtonBlock>
        <Button size="large" color="primary" onClick={onClickHandler}>
          {ls.t("shared.signIn")}
        </Button>
      </S.ButtonBlock>
    </S.Wrapper>
  )
}
