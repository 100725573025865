import dayjs from "dayjs"

const customParseFormat = require("dayjs/plugin/customParseFormat")
const utc = require("dayjs/plugin/utc")
const localizedFormat = require("dayjs/plugin/localizedFormat")
const timezone = require("dayjs/plugin/timezone")

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(localizedFormat)
dayjs.extend(timezone)

export const insertToGridDataState = (gridData, item) => {
  return {
    ...gridData,
    list: [item, ...gridData.list],
  }
}

export const updateToGridDataState = (gridData, item) => {
  return {
    ...gridData,
    list: gridData.list.map((i) => (i.id === item.id ? item : i)),
  }
}

export const deleteToGridDataState = (gridData, item) => {
  return {
    ...gridData,
    list: gridData.list.filter(
      (i) => i.id.toLowerCase() !== item.id.toLowerCase()
    ),
  }
}

export const queryToString = (params) => {
  const toString = (obj, prefix) => {
    const keys = Object.keys(obj)

    const result = []
    keys.forEach((key) => {
      const path = prefix ? [prefix, key].join(".") : key

      switch (typeof obj[key]) {
        case "object":
          result.push(...toString(obj[key], path))
          break
        case "undefined":
          break
        case "array":
          break
        default:
          result.push(`${path}=${obj[key]}`)
      }
    })

    return result
  }

  return toString(params).join("&")
}

export const formatShortDate = (value) => {
  if (!value) return "—"

  return dayjs.unix(value).format("ll")
}

export const formatDate = (value) => {
  if (!value) return "—"

  return dayjs.unix(value).format("DD-MM-YYYY")
}

export const formatDatetime = (value) => {
  if (!value) return "—"

  return dayjs.unix(value).format("DD-MM-YYYY HH:mm:ss")
}

export const renderDatetimeCell = ({ value }) => formatDatetime(value)

export const dateToUTCTimestamp = (value) => {
  return dayjs(value).utc(true).unix()
}

export const isValidUrl = (string) => {
  try {
    const newUrl = new URL(string)
    return newUrl.protocol === "http:" || newUrl.protocol === "https:"
  } catch (err) {
    return false
  }
}

export const isValidDomain = (string) =>
  new RegExp(/^(([\w-]+\.)|(\*\.))+[\w-]+$/).test(string)

export const parseJson = (string) => {
  const parsedValue = JSON.parse(string)
  if (parsedValue !== "") {
    return JSON.parse(parsedValue)
  }

  return {}
}
