import { styled } from "@mui/material"
import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({
  height: "64px",
  display: "flex",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
  alignItems: "center",
  padding: "0 32px",
  background: palette.white,
}))
