import React, { useEffect } from "react"
import { useContextSelector } from "use-context-selector"

import { ChainConfigContext } from "./ChainConfigContext"
import { useFetchChainConfig } from "./hooks/useFetchChainConfig"
import { chainConfigSelectors } from "./selectors"

export const ChainConfigContainer = () => {
  const { data, isLoading } = useFetchChainConfig()

  const chainConfigUpdate = useContextSelector(
    ChainConfigContext,
    chainConfigSelectors.update
  )

  useEffect(() => {
    if (isLoading) {
      chainConfigUpdate({
        isLoading: true,
      })

      return
    }

    chainConfigUpdate({
      data,
      isLoading: false,
    })
  }, [isLoading, data])

  return <></>
}
