import { styled } from "@mui/material"

import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({
  width: "900px",
  padding: "6px 0px",
}))

export const Title = styled("h2")(() => ({
  fontSize: "18px",
}))

export const Items = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, 280px)",
  gridGap: "24px",
  gridRowGap: "20px",
  marginBottom: "20px",
}))

export const Item = styled("div")(() => ({
  width: 280,
}))
export const Label = styled("div")(() => ({
  fontSize: 12,
  lineHeight: "20px",
  fontWeight: 500,
  color: palette.grey400,
}))

export const Value = styled("div")(() => ({
  color: palette.black,
  fontSize: 13,
  lineHeight: "20px",
  fontWeight: 400,
  wordWrap: "break-word",
  marginTop: "6px",
}))

export const ButtonBlock = styled("div")(() => ({
  marginTop: "50px",
  display: "flex",
  justifyContent: "space-between",
  width: "250px",
}))
