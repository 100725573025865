import React from "react"
import { RegisterFormContainer } from "domain/register/RegisterFormContainer"

import * as S from "./styled"

export const RegisterPage = () => {
  return (
    <S.Wrapper>
      <RegisterFormContainer />
    </S.Wrapper>
  )
}
