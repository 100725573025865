import React from "react"

import { ReactComponent as Image } from "assets/images/logo.svg"

import * as S from "./styled"

type TAuthFormHeaderProps = {
  title: string
}
export const AuthFormHeader = ({ title }: TAuthFormHeaderProps) => (
  <S.TitleWrapper>
    <S.CustomLink to="/">
      <Image />
    </S.CustomLink>
    <S.Title>{title}</S.Title>
  </S.TitleWrapper>
)
