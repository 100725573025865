import { localizationService as ls } from "infrastructure/services/LocalizationService"

import { TRegisterForm, TRegisterFormErrors } from "../types"

export const validate = (value: TRegisterForm) => {
  const { email, password, confirmPassword } = value
  const errors: TRegisterFormErrors = {}

  if (!email) errors.email = ls.t("shared.validate.email")
  if (!password) errors.password = ls.t("shared.validate.password")

  const regExp = new RegExp("^(?=.*[A-Z].*[A-Z])(?=.*[0-9].*[0-9]).{8,}$")
  if (password && !regExp.test(password)) {
    errors.password = ls.t("pages.register.validate.strongPassword")
  }
  if (!confirmPassword) {
    errors.confirmPassword = ls.t("pages.register.validate.confirmPassword")
  }
  if (password && confirmPassword && password !== confirmPassword) {
    errors.password = ls.t("pages.register.validate.confirmPasswordMatch")
    errors.confirmPassword = ls.t(
      "pages.register.validate.confirmPasswordMatch"
    )
  }

  return errors
}
