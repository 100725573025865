import { createContext } from "use-context-selector"

import { createProvider } from "domain/createProvider"
import { AddCompanyData } from "./types"

type TUpdateCompanyValue = {
  data?: AddCompanyData
}

export type TCompanyContextState = {
  data: TUpdateCompanyValue
  update?: (value: Omit<AddCompanyData, "id">) => void
}

const initState = {}
export const CompanyContext = createContext(initState)

export const CompanyProvider = createProvider(CompanyContext, initState)
