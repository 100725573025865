import { localizationService as ls } from "infrastructure/services/LocalizationService"

export const getError = (errorCode?: number) => {
  switch (errorCode) {
    case 40001:
      return ls.t("pages.login.errors.userNotFound")
    case 40002:
      return ls.t("pages.login.errors.emailNotVerified")
    default:
      return ls.t("pages.login.errors.serverError")
  }
}
