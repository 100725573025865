import i18next from "i18next"
import { LangEnum } from "infrastructure/enums/LangEnum"
import { errorService } from "infrastructure/services/ErrorService"
// import { loadLocales } from "infrastructure/utils/loadLocales"
import translations from "assets/locales/en-US/general.json"

class LocalizationService {
  private i18next

  constructor() {
    this.i18next = i18next
  }

  async init() {
    await this.changeLang(LangEnum.En)
  }

  async changeLang(lang) {
    try {
      // TODO: try to figure out what's the problem with lazy load
      // const translations = await loadLocales(lang)

      await this.i18next.init({
        lng: lang,
        debug: false,
        fallbackLng: LangEnum.En,
        resources: {
          [lang]: {
            translation: translations,
          },
        },
      })
    } catch (e) {
      errorService.send(e)
      throw e
    }
  }

  t(key: string, options?): string {
    return this.i18next.t(key, options)
  }
}

export const localizationService = new LocalizationService()
