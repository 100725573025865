import { TConfirmBurnDataError } from "domain/applications/types"

export const validate = (value) => {
  const errors: TConfirmBurnDataError = {}

  if (!value.companyName) errors.companyName = "Required"
  if (!value.companyAddress) errors.companyAddress = "Required"
  if (!value.bankAccount) errors.bankAccount = "Required"
  if (!value.bankSwiftCode) errors.bankSwiftCode = "Required"

  return errors
}
