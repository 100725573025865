import React, { useCallback } from "react"
import { useContextSelector } from "use-context-selector"
import { AlertTypeEnum } from "@unlimint/admin-ui-kit"

import { FormModeEnum } from "domain/enums/FormModeEnum"
import { FormContainer } from "domain/core/containers/FormContainer"
import { ApplicationsContext } from "domain/applications/ApplicationsContext"
import { applicationsSelectors } from "domain/applications/selectors"
import { mapToAddReqModel, dataAddToRow } from "domain/applications/mappers"
import { insertToGridDataState } from "domain/core/utils"
import { TUseOpenableState } from "domain/hooks/useOpenable"
import { ChainConfigContext } from "domain/chainConfig/ChainConfigContext"
import { chainConfigSelectors } from "domain/chainConfig/selectors"
import { useAddNotification } from "domain/notifications/hooks/useAddNotification"

import { localizationService as ls } from "infrastructure/services/LocalizationService"

import { ApplicationAddForm } from "view/pages/applications/ApplicationsPage/ApplicationAddForm"
import { validate } from "./validate"

type TAddApplicationContainerProps = {
  modal: TUseOpenableState
}

const initialState = {
  companyId: "",
  numberOfApplications: "",
  operation: "",
  collateralCurrency: "",
  blockchainNetwork: "",
  address: "",
}

export const AddApplicationContainer = ({
  modal,
}: TAddApplicationContainerProps) => {
  const addNotification = useAddNotification()

  const gridData = useContextSelector(
    ApplicationsContext,
    applicationsSelectors.gridData
  )
  const update = useContextSelector(
    ApplicationsContext,
    applicationsSelectors.update
  )
  const chainConfigGridData = useContextSelector(
    ChainConfigContext,
    chainConfigSelectors.data
  )

  const isLoadingChainConfig = useContextSelector(
    ChainConfigContext,
    chainConfigSelectors.isLoading
  )

  const cbSuccess = useCallback(
    (currentData) => {
      const resultData = {
        ...dataAddToRow(currentData),
      }

      update({
        gridData: insertToGridDataState(gridData, resultData),
      })

      addNotification(
        ls.t("pages.applications.notification.addApplicationSuccess"),
        AlertTypeEnum.Success
      )
      modal.onClose()
    },
    [update, gridData]
  )

  const cbFailure = useCallback(() => {
    addNotification(
      ls.t("pages.applications.notification.addApplicationError"),
      AlertTypeEnum.Error
    )

    modal.onClose()
  }, [])

  return (
    <>
      <FormContainer
        mode={FormModeEnum.Add}
        validate={validate}
        initialValues={initialState}
        url="/api/v1/application"
        mapToReqModel={mapToAddReqModel}
        Form={ApplicationAddForm}
        formProps={{
          onCancel: modal.onClose,
          isLoading: isLoadingChainConfig,
          chainConfig: chainConfigGridData || [],
        }}
        cbSuccess={cbSuccess}
        cbFailure={cbFailure}
      />
    </>
  )
}
