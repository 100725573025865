import { storageService } from "infrastructure/services/StorageService"
import { apiService } from "infrastructure/services/ApiService"

class AuthService {
  private email?: string

  async init() {
    return new Promise((resolve, reject) => {
      apiService
        .get("/api/v1/users/me")
        .then((response) => {
          const { data } = response
          this.email = data?.email
          resolve({ email: data?.email })
        })
        .catch(() => {
          reject()
        })
    })
  }

  getEmail(): string | null {
    return this.email
  }

  async logout() {
    await storageService.removeItem("accessToken")
  }
}

export const authService = new AuthService()
