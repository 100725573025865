import { localizationService as ls } from "infrastructure/services/LocalizationService"

import { TLoginForm, TLoginFormErrors } from "../types"

export const validate = (value: TLoginForm) => {
  const errors: TLoginFormErrors = {}

  if (!value.email) errors.email = ls.t("shared.validate.email")
  if (!value.password) errors.password = ls.t("shared.validate.password")

  return errors
}
