import { styled } from "@mui/material"

export const Wrapper = styled("div")(() => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  zIndex: "1",
  pointerEvents: "none",

  "& > div + div": {
    marginTop: "45px",
  },
}))
