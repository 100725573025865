import * as localforage from "localforage"

const store = localforage.createInstance({
  driver: localforage.LOCALSTORAGE,
  name: "stablecoin-fe-personal-account",
  version: 1.0,
})

export const storageService = {
  getItem: (key) => store.getItem(key),
  setItem: (key, value) => store.setItem(key, value),
  removeItem: (key) => store.removeItem(key),
}
