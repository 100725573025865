import React, { useCallback, useState, useEffect, useMemo } from "react"
import { Button } from "@unlimint/admin-ui-kit"
import { useNavigate, useParams } from "react-router-dom"

import { useVerify } from "domain/verify/hooks/useVerify"

import { localizationService as ls } from "infrastructure/services/LocalizationService"

import { AuthFormHeader } from "view/components/AuthFormHeader"

import * as S from "./styled"

enum VerifyEnum {
  Init = "init",
  Success = "success",
  Fail = "fail",
}
export const VerifyPage = () => {
  const { token } = useParams()
  const [state, setState] = useState(VerifyEnum.Init)
  const navigate = useNavigate()

  const { status } = useVerify(token)

  const pageText = useMemo(
    () => ({
      [VerifyEnum.Success]: ls.t("pages.verify.successText"),
      [VerifyEnum.Fail]: ls.t("pages.verify.errorText"),
      [VerifyEnum.Init]: null,
    }),
    []
  )

  useEffect(() => {
    if (!status) return

    if (status === 200) {
      setState(VerifyEnum.Success)
    } else if (status >= 400) {
      setState(VerifyEnum.Fail)
    }
  }, [status])

  const onClickHandler = useCallback(() => {
    navigate("/login")
  }, [])

  return (
    <S.Wrapper>
      <AuthFormHeader title={ls.t("pages.verify.title")} />

      <S.Text>{pageText[state]}</S.Text>

      {state === VerifyEnum.Success && (
        <S.ButtonBlock>
          <Button size="large" color="primary" onClick={onClickHandler}>
            {ls.t("shared.signIn")}
          </Button>
        </S.ButtonBlock>
      )}
    </S.Wrapper>
  )
}
