import { createContext } from "use-context-selector"

import { createProvider } from "domain/createProvider"
import { TChainConfig } from "./types"

type TUpdateValue = {
  data?: TChainConfig[]
  isLoading: boolean
}

export type TChainConfigContextState = {
  data?: TChainConfig[]
  isLoading: boolean
  update?: (value: TUpdateValue) => void
}

export const initState: TChainConfigContextState = {
  data: [],
  isLoading: false,
}

export const ChainConfigContext = createContext(initState)

export const ChainConfigProvider = createProvider(ChainConfigContext, initState)
