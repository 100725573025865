import React, { useMemo, useState } from "react"
import { useContextSelector } from "use-context-selector"
import { Dialog } from "@unlimint/admin-ui-kit"

import { ApplicationsContext } from "domain/applications/ApplicationsContext"
import { EditApplicationContainer } from "domain/applications/EditApplicationContainer"
import { dataToEntity } from "domain/applications/mappers"
import { applicationsSelectors } from "domain/applications/selectors"
import { OperationEnum } from "domain/applications/types"
import { ChainConfigContext } from "domain/chainConfig/ChainConfigContext"
import { chainConfigSelectors } from "domain/chainConfig/selectors"
import { FetchEntityContainer } from "domain/core/containers/FetchEntityContainer"
import { useOpenable } from "domain/hooks/useOpenable"
import { ConfirmMintContainer } from "domain/applications/ConfirmMintContainer"
import { ConfirmBurnContainer } from "domain/applications/ConfirmBurnContainer"

import { ErrorPage } from "view/pages/common/ErrorPage"
import { LoadingPage } from "view/pages/common/LoadingPage"

import { Details } from "./Details"
import * as S from "./styled"

type TApplicationDetailsComponentProps = {
  applicationId: string
}
export const ApplicationDetailsComponent = ({
  applicationId,
}: TApplicationDetailsComponentProps) => {
  const [isEditable, setIsEditable] = useState(false)

  const item = useContextSelector(
    ApplicationsContext,
    applicationsSelectors.item
  )

  const chainConfig = useContextSelector(
    ChainConfigContext,
    chainConfigSelectors.data
  )
  const chainConfigData = chainConfig || []

  const confirmMintModal = useOpenable()
  const confirmBurnModal = useOpenable()

  const handleOnEditClick = () => {
    setIsEditable(true)
  }
  const handleOnCancel = () => {
    setIsEditable(false)
  }

  const handelOnSave = () => {
    setIsEditable(false)
  }

  const handleOnFailure = () => {
    setIsEditable(false)
  }

  const handleOnApprove = (operation: OperationEnum) => {
    if (operation === OperationEnum.Mint) {
      confirmMintModal.onOpen()
    } else if (operation === OperationEnum.Burn) {
      confirmBurnModal.onOpen()
    }
  }

  const Component = useMemo(() => {
    const currentData = item?.data || {}

    if (isEditable) {
      return (
        <EditApplicationContainer
          onSave={handelOnSave}
          onCancel={handleOnCancel}
          onFailure={handleOnFailure}
        />
      )
    }
    if (item.error) {
      return (
        <S.ErrorPageWrapper>
          <ErrorPage />
        </S.ErrorPageWrapper>
      )
    }

    return (
      <Details
        data={currentData}
        onEdit={handleOnEditClick}
        onApprove={handleOnApprove}
        chainConfig={chainConfigData}
      />
    )
  }, [isEditable, item, chainConfigData])

  return (
    <>
      <FetchEntityContainer
        url={`/api/v1/application/${applicationId}`}
        resourceContext={ApplicationsContext}
        resourceSelectors={applicationsSelectors}
        options={{
          mapper: dataToEntity,
        }}
      />

      {item?.isLoading ? <LoadingPage /> : <>{Component}</>}

      <Dialog open={confirmMintModal.open} onClose={confirmMintModal.onClose}>
        <ConfirmMintContainer
          modal={confirmMintModal}
          applicationId={applicationId}
        />
      </Dialog>

      <Dialog open={confirmBurnModal.open} onClose={confirmBurnModal.onClose}>
        <ConfirmBurnContainer
          modal={confirmBurnModal}
          applicationId={applicationId}
        />
      </Dialog>
    </>
  )
}
