export const validate = (value) => {
  const errors: any = {}

  if (!value.name) errors.name = "Required"
  if (!value.country) errors.country = "Required"
  if (!value.registrationNumber) errors.registrationNumber = "Required"
  if (!value.registrationAddress) errors.registrationAddress = "Required"
  if (!value.industry) errors.industry = "Required"
  if (!value.type) errors.type = "Required"
  if (!value.description) errors.description = "Required"
  if (!value.turnover) errors.turnover = "Required"

  if (!value.firstName) errors.firstName = "Required"
  if (!value.secondName) errors.secondName = "Required"
  if (!value.role) errors.role = "Required"
  if (!value.email) errors.email = "Required"
  if (!value.phoneNumber) errors.phoneNumber = "Required"
  if (!value.citizenship) errors.citizenship = "Required"

  return errors
}
