import { styled } from "@mui/material"

import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({
  width: "866px",
  height: "288px",
  background: palette.grey50,
  borderRadius: "8px",
  padding: "24px 32px",
  marginTop: "24px",
  boxSizing: "border-box",
}))

export const Items = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
  gridGap: "24px",
  gridRowGap: "32px",
}))

export const Label = styled("div")(() => ({
  fontSize: 13,
  lineHeight: "20px",
  fontWeight: 500,
  color: palette.black,
}))

export const Value = styled("div")(() => ({
  color: palette.black,
  fontSize: 13,
  lineHeight: "20px",
  fontWeight: 400,
  wordWrap: "break-word",
  marginTop: "6px",
}))

export const ButtonBlock = styled("div")(() => ({
  marginTop: "50px",
  "& > button:first-of-type": {
    marginRight: "12px",
  },
}))

export const CheckboxFieldWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "end",

  "& > label": {
    marginLeft: "-8px",
    "& > .MuiTypography-root": {
      fontSize: 13,
      lineHeight: "20px",
      fontWeight: 500,
    },
  },
}))
