import React, { useMemo } from "react"
import { isEmpty } from "lodash"
import { Button } from "@unlimint/admin-ui-kit"
import { Form } from "formik"

import { TApplicationDetailsErrors } from "domain/applications/types"
import { actionsValues, currencyValues } from "domain/applications/constants"
import { TChainConfig } from "domain/chainConfig/types"

import { InputField } from "view/components/formik/InputField"
import { SelectField } from "view/components/formik/SelectField"
import { LoadingPage } from "view/pages/common/LoadingPage"

import { localizationService } from "infrastructure/services/LocalizationService"

import * as S from "./styled"

type TFormProps = {
  onCancel: () => void
  isSubmitting: boolean
  isLoading: boolean
  chainConfig: TChainConfig[]
  errors?: TApplicationDetailsErrors
}

export const ApplicationAddForm = ({
  onCancel,
  isSubmitting,
  isLoading,
  chainConfig,
  errors,
}: TFormProps) => {
  const collateralCurrencyOptions = useMemo(
    () =>
      currencyValues.map((value) => ({
        label: localizationService.t(`pages.applications.currency.${value}`),
        value,
      })),
    []
  )

  const blockchainNetworkOptions = useMemo(
    () =>
      chainConfig.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    [chainConfig]
  )

  const operationsOptions = useMemo(
    () =>
      actionsValues.map((value) => ({
        label: localizationService.t(`pages.applications.${value}`),
        value,
      })),
    []
  )

  if (isLoading) {
    return (
      <S.Wrapper>
        <LoadingPage />
      </S.Wrapper>
    )
  }

  return (
    <Form>
      <S.Wrapper>
        <S.Items>
          <S.Item>
            <S.Label>Amount</S.Label>
            <S.Value>
              <InputField type="number" name="amount" disabled={isSubmitting} />
            </S.Value>
          </S.Item>
          <S.Item>
            <S.Label>Operation</S.Label>
            <S.Value>
              <SelectField
                name="operation"
                options={operationsOptions}
                disabled={isSubmitting}
              />
            </S.Value>
          </S.Item>
          <S.Item>
            <S.Label>Collateral currency</S.Label>
            <S.Value>
              <SelectField
                name="collateralCurrency"
                disabled={isSubmitting}
                options={collateralCurrencyOptions}
              />
            </S.Value>
          </S.Item>
          <S.Item>
            <S.Label>Blockchain network</S.Label>
            <S.Value>
              <SelectField
                name="blockchainNetwork"
                disabled={isSubmitting}
                options={blockchainNetworkOptions}
              />
            </S.Value>
          </S.Item>
          <S.Item>
            <S.Label>Address</S.Label>
            <S.Value>
              <InputField name="address" disabled={isSubmitting} />
            </S.Value>
          </S.Item>
        </S.Items>

        <S.ButtonBlock>
          <Button size="small" onClick={onCancel} color="secondary">
            Cancel
          </Button>
          <Button
            size="small"
            type="submit"
            disabled={isSubmitting || !isEmpty(errors)}
          >
            Apply
          </Button>
        </S.ButtonBlock>
      </S.Wrapper>
    </Form>
  )
}
