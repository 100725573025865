import React from "react"
import * as S from "./styled"

type TPageProps = {
  children: JSX.Element | JSX.Element[]
  withHeader?: boolean
}
export const PageContent = ({ children, withHeader }: TPageProps) => (
  <S.Wrapper withHeader={withHeader}>{children}</S.Wrapper>
)
