import { ReactElement } from "react"
import * as S from "./styled"

type TSelectBlockProps = {
  text?: string | ReactElement
  onClick?: () => void
}
export const SelectBlock = ({ text, onClick }: TSelectBlockProps) => (
  <S.Wrapper onClick={() => onClick?.()}>{text}</S.Wrapper>
)
