import React from "react"
import { useParams } from "react-router-dom"

import { useTitle } from "domain/common/hooks/useTitle"
import { ApplicationsProvider } from "domain/applications/ApplicationsContext"

import { DetailsPageHeader } from "view/components/DetailsPageHeader"
import { PageContent } from "view/components/PageContent"

import { ApplicationDetailsComponent } from "./ApplicationDetailsComponent"
import * as S from "./styled"

type TApplicationDetailsProps = {
  applicationId: string
}
const ApplicationDetails = ({ applicationId }: TApplicationDetailsProps) => {
  useTitle("Details of Application")

  return (
    <>
      <DetailsPageHeader />

      <PageContent withHeader>
        <S.Header>
          <S.Title>{applicationId || "Application ID"}</S.Title>
        </S.Header>

        <ApplicationDetailsComponent applicationId={applicationId} />
      </PageContent>
    </>
  )
}

export const ApplicationDetailsPage = () => {
  const { applicationId } = useParams()

  return (
    <ApplicationsProvider>
      <ApplicationDetails applicationId={applicationId} />
    </ApplicationsProvider>
  )
}
