import React, { useCallback, useEffect, useState } from "react"
import { useContextSelector } from "use-context-selector"
import { AlertTypeEnum } from "@unlimint/admin-ui-kit"

import { TUseOpenableState } from "domain/hooks/useOpenable"
import { mapToConfirmModalData } from "domain/applications/mappers"

import { ConfirmMintModal } from "view/pages/applications/ApplicationDetails/ConfirmMintModal"
import { localizationService as ls } from "infrastructure/services/LocalizationService"
import { useAddNotification } from "../../notifications/hooks/useAddNotification"
import { ApplicationsContext } from "../ApplicationsContext"
import { useConfirmMint } from "../hooks/useConfirmMint"
import { applicationsSelectors } from "../selectors"

type TConfirmMintContainerProps = {
  modal: TUseOpenableState
  applicationId?: string
}
export const ConfirmMintContainer = ({
  modal,
  applicationId,
}: TConfirmMintContainerProps) => {
  const [alreadyUpdate, setAlreadyUpdate] = useState(false)

  const {
    isLoading,
    send,
    data: responseData,
    error: responseError,
  } = useConfirmMint(applicationId)

  const addNotification = useAddNotification()

  const item = useContextSelector(
    ApplicationsContext,
    applicationsSelectors.item
  )

  const update = useContextSelector(
    ApplicationsContext,
    applicationsSelectors.update
  )

  const data = item?.data || {}

  useEffect(() => {
    if (!responseError || alreadyUpdate) return

    addNotification(
      ls.t("pages.applications.notification.confirmMintError"),
      AlertTypeEnum.Error
    )

    setAlreadyUpdate(true)
    modal.onClose()
  }, [responseError, addNotification, alreadyUpdate])

  useEffect(() => {
    if (!responseData || alreadyUpdate) return

    addNotification(
      ls.t("pages.applications.notification.confirmMintSuccess"),
      AlertTypeEnum.Success
    )
    update({
      item: {
        ...item,
        data: {
          ...data,
          status: responseData.status,
        },
      },
    })

    setAlreadyUpdate(true)
    modal.onClose()
  }, [responseData, update, addNotification, alreadyUpdate])

  const onConfirm = useCallback(() => {
    send()
  }, [item, data, update])

  return (
    <ConfirmMintModal
      data={mapToConfirmModalData(item.data || {})}
      onCancel={modal.onClose}
      onConfirm={onConfirm}
      isLoading={isLoading}
    />
  )
}
