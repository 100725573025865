import { Button } from "@unlimint/admin-ui-kit"

import { TConfirmMintData } from "domain/applications/types"

import { localizationService as ls } from "infrastructure/services/LocalizationService"
import { renderToStaticMarkup } from "react-dom/server"

import { LoadingPage } from "view/pages/common/LoadingPage"

import { paymentInfo } from "./constants"
import * as S from "./styled"

type TConfirmActionModalProps = {
  data: TConfirmMintData
  onCancel: () => void
  onConfirm: () => void

  isSubmitting?: boolean
  isLoading?: boolean
}
export const ConfirmMintModal = ({
  data,
  onCancel,
  onConfirm,
  isSubmitting,
  isLoading,
}: TConfirmActionModalProps) => {
  if (isSubmitting || isLoading) {
    return (
      <S.Wrapper>
        <LoadingPage />
      </S.Wrapper>
    )
  }

  const amountText = renderToStaticMarkup(<strong>{data.amount}</strong>)
  const currencyText = renderToStaticMarkup(
    <strong>{ls.t(`pages.applications.currency.${data.currency}`)}</strong>
  )

  const text = ls.t("pages.applications.confirmMint.text", {
    amount: amountText,
    currency: currencyText,
  })

  return (
    <S.Wrapper>
      <S.Title>{ls.t("shared.confirmTx")}</S.Title>

      <S.Content>
        <S.Text>
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </S.Text>
        <S.Item>
          <S.Label>
            {ls.t("pages.applications.confirmMint.fields.accountDetails")}
          </S.Label>
          <S.Value>{paymentInfo.accountDetails}</S.Value>
        </S.Item>
        <S.Item>
          <S.Label>
            {ls.t("pages.applications.confirmMint.fields.iban")}
          </S.Label>
          <S.Value>{paymentInfo.iban}</S.Value>
        </S.Item>
        <S.Item>
          <S.Label>
            {ls.t("pages.applications.confirmMint.fields.beneficiaryBank")}
          </S.Label>
          <S.Value>{paymentInfo.beneficiaryBank}</S.Value>
        </S.Item>
        <S.Item>
          <S.Label>
            {ls.t("pages.applications.confirmMint.fields.swift")}
          </S.Label>
          <S.Value>{paymentInfo.swift}</S.Value>
        </S.Item>
        <S.Item>
          <S.Label>
            {ls.t("pages.applications.confirmMint.fields.beneficiary")}
          </S.Label>
          <S.Value>{paymentInfo.beneficiary}</S.Value>
        </S.Item>
      </S.Content>

      <S.Buttons>
        <Button size="small" onClick={onConfirm}>
          {ls.t("pages.applications.confirmMint.confirm")}
        </Button>
        <Button size="small" color="secondary" onClick={onCancel}>
          {ls.t("shared.close")}
        </Button>
      </S.Buttons>
    </S.Wrapper>
  )
}
