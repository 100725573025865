import { styled } from "@mui/material"
import { palette } from "../../../themes/palette"

export const Wrapper = styled("div")(() => ({}))

export const Text = styled("div")(() => ({
  fontFamily: "JetBrains Mono",
  fontSize: 14,
  fontWeight: 400,
  fontStyle: "normal",
  textAlign: "center",
  lineHeight: "20px",
  color: palette.grey500,
  whiteSpace: "pre-wrap",
}))

export const ButtonBlock = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "36px",
  "& > button:first-of-type": {
    marginRight: "12px",
  },
  "& > button": {
    fontFamily: "JetBrains Mono",
  },
}))
