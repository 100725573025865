import { styled } from "@mui/material"
import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({
  height: "32px",
  display: "flex",
  padding: "0 12px",
  alignItems: "center",
  justifyContent: "flex-start",
  borderRadius: "4px",
  background: palette.grey100,

  fontFamily: "JetBrains Mono",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "20px",
}))
