import { createContext } from "use-context-selector"

import { createProvider } from "domain/createProvider"
import { TDataGridContext, TItemContext } from "../types"
import { getDataGridInitState } from "../utils"
import { TApplicationData } from "./types"

type TUpdateValue = {
  gridData?: TDataGridContext<TApplicationData>
  item?: TItemContext<any>
}

export type TApplicationsContextState = {
  gridData: TDataGridContext<TApplicationData>
  item: TItemContext<any>
  update?: (value: TUpdateValue) => void
}

export const initState: TApplicationsContextState = getDataGridInitState()

export const ApplicationsContext = createContext(initState)

export const ApplicationsProvider = createProvider(
  ApplicationsContext,
  initState
)
