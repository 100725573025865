import { useCallback, useEffect, useState } from "react"
import { Search } from "@unlimint/admin-ui-kit"
import * as S from "./styled"

type TSearchBlockProps = {
  placeholder?: string
  columns?: Array<string>
  list?: Array<object>
  onChange?: (list: Array<object>) => void
}

export const SearchBlock = ({
  placeholder,
  columns,
  list,
  onChange,
}: TSearchBlockProps) => {
  const [value, setValue] = useState("")

  useEffect(() => {
    const searchValue = value.trim()

    if (!searchValue) {
      onChange(list)
      return
    }

    const filteredList = list.filter((item) => {
      const regex = new RegExp(searchValue, "gi")

      return columns.reduce(
        (acc, column) => acc || item[column].search(regex) !== -1,
        false
      )
    })

    if (!!searchValue && filteredList.length !== list.length)
      onChange(filteredList)
  }, [value, list, columns, onChange])

  const handlerSearch = useCallback(
    (e) => {
      setValue(e.target.value)
    },
    [setValue]
  )

  const handlerClear = useCallback(() => {
    setValue("")
  }, [setValue])

  const handlerBlur = useCallback(() => {
    setValue((prev) => prev.trim())
  }, [setValue])

  return (
    <S.Wrapper>
      <Search
        placeholder={placeholder}
        value={value}
        onChange={handlerSearch}
        onClickEndIcon={handlerClear}
        onBlur={handlerBlur}
        fullWidth
      />
    </S.Wrapper>
  )
}
