import React from "react"
import { useNavigate } from "react-router-dom"
import { Button, ArrowIcon16 } from "@unlimint/admin-ui-kit"

import * as S from "./styled"

export const DetailsPageHeader = () => {
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <S.Wrapper>
      <Button
        onClick={handleGoBack}
        size="small"
        variant="outlined"
        color="secondary"
        startIcon={<ArrowIcon16 />}
      >
        Go back
      </Button>
    </S.Wrapper>
  )
}
