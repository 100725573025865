import { ReactComponent as Image } from "assets/images/error.svg"

import * as S from "./styled"

export const ErrorPage = () => (
  <S.Wrapper>
    <Image />
    <S.Text>Unable to get data. Please try again later.</S.Text>
  </S.Wrapper>
)
