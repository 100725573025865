import React, { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"

import { mapToAddReqModel } from "domain/login/mappers"
import { FormModeEnum } from "domain/enums/FormModeEnum"
import { FormContainer } from "domain/core/containers/FormContainer"

import { LoginForm } from "view/pages/login/LoginPage/LoginForm"

import { localizationService as ls } from "infrastructure/services/LocalizationService"
import { storageService } from "../../../infrastructure/services/StorageService"
import { getError } from "./utils"

import { validate } from "./validate"

const initialState = {
  email: "",
  password: "",
}
export const LoginFormContainer = () => {
  const navigate = useNavigate()
  const [error, setError] = useState(null)

  const cbSuccess = useCallback(
    (value) => {
      const { accessToken } = value
      if (accessToken) {
        storageService.setItem("accessToken", accessToken).then(() => {
          navigate("/")
        })
      } else {
        setError(ls.t("pages.login.errors.serverError"))
      }
    },
    [navigate]
  )

  const cbFailure = useCallback((data) => {
    const errorCode = data.response?.data?.code
    const currentError = getError(errorCode)
    setError(currentError)
  }, [])

  const cbSubmit = useCallback(() => {
    setError(null)
  }, [])

  const onSignUp = useCallback(() => {
    navigate("/register")
  }, [navigate])

  return (
    <FormContainer
      mode={FormModeEnum.Add}
      initialValues={initialState}
      validate={validate}
      validateOnSubmit
      url="/api/v1/users/login"
      mapToReqModel={mapToAddReqModel}
      Form={LoginForm}
      formProps={{
        error,
        onSignUp,
      }}
      cbSuccess={cbSuccess}
      cbFailure={cbFailure}
      cbSubmit={cbSubmit}
    />
  )
}
