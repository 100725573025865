import { createContext } from "use-context-selector"

import { createProvider } from "domain/createProvider"

import { TNotification } from "./types"

type TUpdateValue = {
  notifications: TNotification[]
}

export type TNotificationsContextState = {
  notifications: TNotification[]
  update?: (value: TUpdateValue) => void
}

export const initState: TNotificationsContextState = {
  notifications: [],
}

export const NotificationsContext = createContext(initState)

export const NotificationsProvider = createProvider(
  NotificationsContext,
  initState
)
