import { useRequest } from "domain/core/hooks/useRequest"
import { ApiMethodEnum } from "domain/core/enums"

export const useHasCompany = () => {
  const { data, isLoading, send } = useRequest({
    url: "/api/v1/users/has-company",
    sendOnStart: true,
    method: ApiMethodEnum.Get,
  })

  return {
    updateStatus: send,
    isLoading: isLoading || data?.list === undefined,
    hasCompany: data?.hasCompany,
  }
}
