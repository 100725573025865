import { TApplicationDetailsErrors } from "domain/applications/types"

export const validate = (value) => {
  const errors: TApplicationDetailsErrors = {}

  if (!value.amount) errors.amount = "Required"
  if (!value.status) errors.status = "Required"
  if (!value.collateralCurrency) errors.collateralCurrency = "Required"
  if (!value.address) errors.address = "Required"

  return errors
}
