import { styled } from "@mui/material"
import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  background: palette.grey100,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

export const FormContent = styled("div")(() => ({
  backgroundColor: palette.white,
  width: "360px",
  padding: "40px",
  boxShadow: "0 0 60px 2px rgba(0, 0, 0, 0.1)",
}))
