import React from "react"
import { Form } from "formik"
import { Button } from "@unlimint/admin-ui-kit"

import { InputField } from "view/components/formik/InputField"
import { FormError } from "view/components/FormError"
import { AuthFormHeader } from "view/components/AuthFormHeader"

import { localizationService as ls } from "infrastructure/services/LocalizationService"

import * as S from "./styled"

type TLoginFormProps = {
  isSubmitting: boolean
  error?: string
  onSignUp?: () => void
}

export const LoginForm = ({
  isSubmitting,
  error,
  onSignUp,
}: TLoginFormProps) => {
  return (
    <Form>
      <AuthFormHeader title={ls.t("pages.login.title")} />

      <S.Items>
        <div>
          <S.Label>{ls.t("shared.email")}</S.Label>
          <S.Value>
            <InputField type="email" name="email" size="large" />
          </S.Value>
        </div>
        <div>
          <S.Label>{ls.t("shared.password")}</S.Label>
          <S.Value>
            <InputField type="password" name="password" size="large" />
          </S.Value>
        </div>
      </S.Items>

      <FormError error={error} />

      <S.ButtonBlock>
        <Button
          size="large"
          color="primary"
          type="submit"
          disabled={isSubmitting}
        >
          {ls.t("shared.signIn")}
        </Button>
        <Button
          size="large"
          color="secondary"
          onClick={onSignUp}
          disabled={isSubmitting}
        >
          {ls.t("shared.signUp")}
        </Button>
      </S.ButtonBlock>
    </Form>
  )
}
