import React from "react"

import { Field } from "formik"

import { Select } from "@unlimint/admin-ui-kit"

export const SelectField = ({
  name,
  label,
  placeholder,
  disabled,
  options,
  multiple = false,
}) => {
  return (
    <Field name={name}>
      {({ field, form: { errors, touched } }) => (
        <Select
          options={options}
          name={name}
          disabled={disabled}
          onChange={field.onChange}
          placeholder={placeholder}
          header={label}
          error={!!errors[name] && !!touched[name]}
          helperText={!!touched[name] && errors[name]}
          defaultValue={field.value}
          multiple={multiple}
          fullWidth
        />
      )}
    </Field>
  )
}
