import React, { useCallback } from "react"
import { useContextSelector } from "use-context-selector"
import { AlertTypeEnum } from "@unlimint/admin-ui-kit"

import { FormModeEnum } from "domain/enums/FormModeEnum"
import { FormContainer } from "domain/core/containers/FormContainer"

import { ApplicationsContext } from "domain/applications/ApplicationsContext"
import { applicationsSelectors } from "domain/applications/selectors"
import { useAddNotification } from "domain/notifications/hooks/useAddNotification"
import {
  mapToEditFormData,
  mapToEditReqModel,
  dataToEntity,
} from "domain/applications/mappers"

import { localizationService as ls } from "infrastructure/services/LocalizationService"

import { ApplicationEditForm } from "view/pages/applications/ApplicationDetails/Form"
import { ChainConfigContext } from "../../chainConfig/ChainConfigContext"
import { chainConfigSelectors } from "../../chainConfig/selectors"
import { validate } from "./validate"

type TEditApplicationContainerProps = {
  onCancel: () => void
  onSave: () => void
  onFailure: () => void
}

export const EditApplicationContainer = ({
  onCancel,
  onSave,
  onFailure,
}: TEditApplicationContainerProps) => {
  const addNotification = useAddNotification()

  const item = useContextSelector(
    ApplicationsContext,
    applicationsSelectors.item
  )
  const gridData = useContextSelector(
    ApplicationsContext,
    applicationsSelectors.gridData
  )
  const update = useContextSelector(
    ApplicationsContext,
    applicationsSelectors.update
  )

  const chainConfigGridData = useContextSelector(
    ChainConfigContext,
    chainConfigSelectors.data
  )

  const isLoadingChainConfig = useContextSelector(
    ChainConfigContext,
    chainConfigSelectors.isLoading
  )

  const data = item?.data || {}

  const cbSuccess = useCallback(
    (currentData) => {
      update({
        item: {
          ...item,
          data: dataToEntity(currentData),
        },
      })

      addNotification(
        ls.t("pages.applications.notification.editApplicationSuccess"),
        AlertTypeEnum.Success
      )
      onSave()
    },
    [update, gridData, item]
  )

  const cbFailure = useCallback(() => {
    addNotification(
      ls.t("pages.applications.notification.editApplicationError"),
      AlertTypeEnum.Error
    )

    onFailure?.()
  }, [])

  return (
    <FormContainer
      mode={FormModeEnum.Edit}
      validate={validate}
      initialValues={mapToEditFormData(data)}
      url={`/api/v1/application/${data.id}`}
      mapToReqModel={mapToEditReqModel}
      Form={ApplicationEditForm}
      formProps={{
        isLoading: isLoadingChainConfig,
        onCancel,
        chainConfig: chainConfigGridData || [],
      }}
      cbSuccess={cbSuccess}
      cbFailure={cbFailure}
    />
  )
}
