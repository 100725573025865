import { TApplicationAddDataErrors } from "domain/applications/types"

export const validate = (value) => {
  const errors: TApplicationAddDataErrors = {}

  if (!value.collateralCurrency) errors.collateralCurrency = "Required"
  if (!value.amount) errors.amount = "Required"
  if (!value.operation) errors.operation = "Required"
  if (!value.blockchainNetwork) errors.blockchainNetwork = "Required"
  if (!value.address) errors.address = "Required"
  if (value.address && !value.address.match(/^0x[0-9a-fA-F]{40}$/g)) {
    errors.address = "Invalid Format"
  }

  return errors
}
