import { styled } from "@mui/material"

import { palette } from "view/themes/palette"

export const Wrapper = styled("div")(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}))

export const Text = styled("div")(() => ({
  fontFamily: "JetBrains Mono",
  fontSize: 13,
  fontWeight: 400,
  fontStyle: "normal",
  textAlign: "center",
  lineHeight: "20px",
  color: palette.grey500,
  whiteSpace: "pre-wrap",
  marginTop: "12px",
}))
