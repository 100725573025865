import { ReactComponent as LoadingIcon } from "assets/images/loader.svg"

import * as S from "./styled"

export const LoadingPage = () => {
  return (
    <S.Wrapper>
      <LoadingIcon />
    </S.Wrapper>
  )
}
