import { useRequest } from "domain/core/hooks/useRequest"
import { ApiMethodEnum } from "domain/core/enums"

export const useConfirmMint = (
  applicationId?: string
): {
  isLoading: boolean
  send: () => void
  data: any
  error: any
} => {
  const { isLoading, send, data, error } = useRequest({
    url: "/api/v1/application/confirm",
    method: ApiMethodEnum.Post,
    body: {
      id: applicationId,
    },
  })

  return {
    isLoading,
    send,
    data,
    error,
  }
}
